import { createContext, useState, ReactNode } from 'react';
import useCart from '../services/CartSevice/CartService';
interface CartCountContextType {
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
}

export const CartCountContext = createContext<CartCountContextType | null>(null);

export const CartCountProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { cartItems } = useCart();  
  
  const [count, setCount] = useState<number>(cartItems.length || 0);

    return (
        <CartCountContext.Provider value={{ count, setCount }}>
            {children}
        </CartCountContext.Provider>
    );
};
