import React, {useEffect, useState} from "react";
import {UserInfoContext} from "./UserInfoContext";
import {getUserInfo} from "../services/UserInfoService/UserInfoService";
import {UserInfo} from "../model/UserInfo";
import accessTokenService from "../services/AccessTokenService/AccessTokenService.ts";
import {useAuth} from "react-oidc-context";
import {LS_ACCESS_TOKEN, ReadableRoleNames, Roles} from "../constants/EsapConstants.ts";

export const UserInfoProvider = ({children}: { children: React.ReactNode }) => {
    const [user, setUser] = useState<UserInfo | null>(null);
    const auth = useAuth();

    useEffect(() => {

        let isMounted = true;

        (async () => {

            if (!auth || auth.activeNavigator || auth.isLoading) {
                return;
            }
            const checkUserInfoUrl = import.meta.env.VITE_REACT_APP_USERINFO_URL;
                // const checkUserInfoUrl = "http://localhost:35020/esap-openid/userinfo";
    
                // GET user info
                try {
                    const response = await fetch(checkUserInfoUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    if (!isMounted) return;
                    if (response.ok) {
                        const data = await response.json();
                        if (data && data.roles && auth && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                            // if ( auth && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                            accessTokenService.clearOidcTokens();
                            // if (data.roles.includes("administrator")) {
                            //     auth.signinRedirect().then(() => {
                            //         console.log("Redirecting to login page");
                            //     });
                            // } else{
                            //     auth.signinSilent();
                            // }
                            await auth.signinRedirect();
                            console.log("Redirecting to login page");
    
                        } else if (data && data.message && auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                            await auth.signoutSilent();
                        }
                        return data;
                    } else {
                        console.error("Error fetching user info:", response.statusText);
                    }
                } catch (error) {
                    console.error("Fetch error:", error);
                }
            
        })();

        return () => {
            isMounted = false;  // Clean up on component unmount
        };
    }, [auth]);

    useEffect(() => {
        if (auth.user && auth.user.access_token && auth.isAuthenticated) {
            const userInfo = {...getUserInfo(auth.user.access_token), isAuthenticated: auth.isAuthenticated};
            accessTokenService.setAccessToken({[LS_ACCESS_TOKEN]: auth.user.access_token});
            setUser(userInfo);
        } else {
            if (!auth || auth.activeNavigator || auth.isLoading) {
                return;
            }
            (async () => {
                // const getAnonymRolesUrl = import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL + "/api/anonymous-roles";
                // const entraAnonymousGroupName = import.meta.env.VITE_REACT_APP_ENTRA_GROUP_ANONYMOUS;
                // try {
                //     const response = await fetch(getAnonymRolesUrl, {
                //         method: 'GET',
                //         headers: {
                //             'Content-Type': 'application/json',
                //         }
                //     });
                //     if (response.ok) {
                //         const data = await response.json();
                //         if (data) {
                //             setUser({
                //                 name: "",
                //                 username: "",
                //                 roles: data[entraAnonymousGroupName],
                //                 rolesReadable: data[entraAnonymousGroupName].map((role: string) => ReadableRoleNames[role]),
                //                 picture: "",
                //                 email: ""
                //             });
                //         } else {
                //             console.error("No anonymous user data", response.statusText);
                //         }
                //     } else {
                //         console.error("Error fetching anonymous user roles:", response.statusText);
                //     }


                // } catch (error) {
                //     console.error("Fetch error:", error);
                // }
                setUser({
                    name: "",
                    username: "",
                    roles: [
                        Roles.INFORMATION_VIEWER_ROLE, 
                        Roles.DOWNLOAD_SERVICE_ROLE, 
                        Roles.TRANSLATION_ROLE,
                        Roles.WEB_PORTAL_ROLE,
                        Roles.SEARCH_ENGINE_ROLE,
                        Roles.DATA_FLOW_ROLE,
                        Roles.ESAP_DATA_ADMINISTRATOR_ROLE,
                        Roles.ESAP_DATA_MANAGER_ROLE,
                        Roles.ESAP_SUPPORT_ROLE,
                        Roles.SEARCH_ENGINE_ROLE
                    ],
                    rolesReadable: [
                        ReadableRoleNames.INFORMATION_VIEWER_ROLE, 
                        ReadableRoleNames.DOWNLOAD_SERVICE_ROLE, 
                        ReadableRoleNames.TRANSLATION_ROLE,
                        ReadableRoleNames.WEB_PORTAL_ROLE,
                        ReadableRoleNames.SEARCH_ENGINE_ROLE,
                        ReadableRoleNames.DATA_FLOW_ROLE,
                        ReadableRoleNames.ESAP_DATA_ADMINISTRATOR_ROLE,
                        ReadableRoleNames.ESAP_DATA_MANAGER_ROLE,
                        ReadableRoleNames.ESAP_SUPPORT_ROLE,
                        ReadableRoleNames.SEARCH_ENGINE_ROLE
                    ],
                    picture: "",
                    email: ""
                });
            })();
        }
    }, [auth]);

    useEffect(() => {
        if (user) {
            console.log("User roles:", user.roles);
        }
    }, [user]);

    return (
        <UserInfoContext.Provider value={{user, setUser}}>
            {children}
        </UserInfoContext.Provider>
    );
};
