import React, { createContext } from "react";
import { UserInfo } from "../model/UserInfo";

export interface UserContextType {
    user: UserInfo | null;
    setUser: React.Dispatch<React.SetStateAction<UserInfo | null>>;
}

export const UserInfoContext = createContext<UserContextType>({
    user: null,
    setUser: () => {},
});