interface JsonDisplayProps {
    fileContent: object;
}

const JsonDisplay: React.FC<JsonDisplayProps> = ({ fileContent }) => {
    return (
        <>
            <pre>{JSON.stringify(fileContent, null, 2)}</pre>
        </>
    );
}

export default JsonDisplay;
