import accessTokenService from '../../services/AccessTokenService/AccessTokenService';
import {RBAC} from "../../services/RBAC/RBAC.tsx";
import {useUserInfo} from "../../hooks/useUserInfo.ts";
import React from "react";

interface LayoutWithAuthProps {
    comp: JSX.Element
    allowedRoles: string[]
    selectedNav?: string
    requiresAuth?: boolean
}

const NOTIFY_USER_BEFORE_TOKEN_EXPIRATION_IN_MINUTES: number = 2;
const NOTIFY_USER_BEFORE_TOKEN_EXPIRATION_INTERVAL_IN_SECONDS: number = 20;

const LayoutWithAuth = (props: LayoutWithAuthProps) => {
    const {user} = useUserInfo();

    React.useEffect(() => {
        let notifyUserTokenExpiringInterval: NodeJS.Timeout;

        const setupTokenNotification = () => {
            const token = accessTokenService.getToken();

            if (token) {
                const tokenPayload = JSON.parse(atob(token.split('.')[1]));
                const tokenExpTime: number = tokenPayload.exp;
                const notifyUserTime: number = tokenExpTime - (NOTIFY_USER_BEFORE_TOKEN_EXPIRATION_IN_MINUTES * 60);

                notifyUserTokenExpiringInterval = setInterval(function () {
                    const epochTimeNow: number = Math.floor(Date.now() / 1000);
                    if (notifyUserTime <= epochTimeNow) {
                        clearInterval(notifyUserTokenExpiringInterval);
                        const confirm = window.confirm(`Your session is about to expire (~${NOTIFY_USER_BEFORE_TOKEN_EXPIRATION_IN_MINUTES} minutes). Do you want to renew your session?`);
                        if (confirm) {
                            window.location.href = import.meta.env.VITE_REACT_APP_LOGIN_URL;
                        }
                    }
                }, NOTIFY_USER_BEFORE_TOKEN_EXPIRATION_INTERVAL_IN_SECONDS * 1000);
            }
        }

        setupTokenNotification();

        return () => {
            clearInterval(notifyUserTokenExpiringInterval);
        }
    }, []);

    let component: JSX.Element = <></>;

    const allowedRoles = props.allowedRoles;

    if (allowedRoles?.length > 0 && user?.isAuthenticated) {
        const userInfoRoles = user.roles;
        component = (
            <RBAC userRoles={userInfoRoles} allowedRoles={allowedRoles ?? []}>
                {props.comp}
            </RBAC>
        );
    } else {
        component =
            <>
                <div>{props.comp}</div>
            </>;
    }
    return component;
};

export default LayoutWithAuth;
