export async function fetchTranslations(languageCode: string = 'en') {

    const apiUrl = import.meta.env.VITE_REACT_APP_DRUPAL_API_URL;

    if (!apiUrl) {
        console.error('API URL is not defined');
        return null;
    }

    return await fetch(
        `${apiUrl}/jsonapi/taxonomy/translations/${languageCode}`,
        { method: 'get' }
    )
        .then(async (resp) => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw new Error('Error fetching translations');
            }
        })
        .then((json) => {
            if (json) {
                return json;
            } else {
                return {};
            }
        })
        .catch((error) => {
            console.error('Fetch translations error:', error);
            return {};
        });
}
