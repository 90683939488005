import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
    en: {
        // Filters
        freeText: "freeText",
        typeOfInformation: "typeOfInformation",
        entityLEI: "entityLEI",
        entityName: "entityName",
        sizeOfEntity: "sizeOfEntity",
        submissionDate: "submissionDate",
        submissionDateFrom: "submissionDateFrom",
        submissionDateTo: "submissionDateTo",
        dateOrPeriodCovered: "dateOrPeriodCovered",
        dateOrPeriodCoveredFrom: "dateOrPeriodCoveredFrom",
        dateOrPeriodCoveredTo: "dateOrPeriodCoveredTo",
        submittingEntityName: "submittingEntityName",
        submittingEntityLEI: "submittingEntityLEI",
        sizeOfSubmittingEntity: "sizeOfSubmittingEntity",
        leiStatus: "leiStatus",
        countryOfRegisteredOffice: "countryOfRegisteredOffice",
        industrySector: "industrySector",
        originalLanguage: "originalLanguage",
        legalFramework: "legalFramework",
        collectionBodyName: "collectionBodyName",
        homeMemberStateOfEntity: "homeMemberStateOfEntity",
        hostMemberStateOfEntity: "hostMemberStateOfEntity",
        isin: "isin",
        fisn: "fisn",
        voluntaryInfoFlag: "voluntaryInfoFlag",
        historicalInfoFlag: "historicalInfoFlag",

        // Categories
        categoryNameMain: "Main",
        categoryNameAdditional: "Additional",

        // General
        noResults: "There are no results",

        // Labels
        labels: {
            freeTextLabel: "Free text",
            typeOfInformationLabel: "Type of information",
            entityLEILabel: "Entity LEI",
            entityNameLabel: "Entity name",
            sizeOfEntityLabel: "Size of the Entity",
            submissionDateLabel: "Submission date / time",
            dateOrPeriodCoveredLabel: "Date or period covered",
            submittingEntityNameLabel: "Submitting entity name",
            submittingEntityLEILabel: "Submitting entity LEI",
            sizeOfSubmittingEntityLabel: "Size of the submitting entity",
            leiStatusLabel: "LEI status",
            countryOfRegisteredOfficeLabel: "Country of registered office",
            industrySectorLabel: "Industry sector(s)",
            originalLanguageLabel: "Original language(s)",
            legalFrameworkLabel: "Legal framework",
            collectionBodyNameLabel: "Collection Body name",
            homeMemberStateOfEntityLabel: "Home member state of the entity concerned",
            hostMemberStateOfEntityLabel: "Host member state of the entity concerned",
            isinLabel: "ISIN",
            fisnLabel: "FISN",
            voluntaryInfoFlagLabel: "Voluntary information flag",
            historicalInfoFlagLabel: "Historical information flag",
            aboutLabel: "About",
            searchLabel: "Search",
            submissionsLabel: "Submissions",
            reportingEntitiesLabel: "Reporting entities",
            fileUploadLabel: "File upload",
            historyOfSubmissionsLabel: "History of submissions",
        }
    },

    fr: {
        // Filters
        freeText: "freeText",
        typeOfInformation: "typeOfInformation",
        entityLEI: "entityLEI",
        entityName: "entityName",
        sizeOfEntity: "sizeOfEntity",
        submissionDate: "submissionDate",
        submissionDateFrom: "submissionDateFrom",
        submissionDateTo: "submissionDateTo",
        dateOrPeriodCovered: "dateOrPeriodCovered",
        dateOrPeriodCoveredFrom: "dateOrPeriodCoveredFrom",
        dateOrPeriodCoveredTo: "dateOrPeriodCoveredTo",
        submittingEntityName: "submittingEntityName",
        submittingEntityLEI: "submittingEntityLEI",
        sizeOfSubmittingEntity: "sizeOfSubmittingEntity",
        leiStatus: "leiStatus",
        countryOfRegisteredOffice: "countryOfRegisteredOffice",
        industrySector: "industrySector",
        originalLanguage: "originalLanguage",
        legalFramework: "legalFramework",
        collectionBodyName: "collectionBodyName",
        homeMemberStateOfEntity: "homeMemberStateOfEntity",
        hostMemberStateOfEntity: "hostMemberStateOfEntity",
        isin: "isin",
        fisn: "fisn",
        voluntaryInfoFlag: "voluntaryInfoFlag",
        historicalInfoFlag: "historicalInfoFlag",

        // Categories
        categoryNameMain: "Main",
        categoryNameAdditional: "Additional",

        // General
        noResults: "Il n'y a pas de résultats",

        // Labels
        labels: {
            freeTextLabel: "Texte libre",
            typeOfInformationLabel: "Type d'information",
            entityLEILabel: "LEI de l'entité",
            entityNameLabel: "Nom de l'entité",
            sizeOfEntityLabel: "Taille de l'entité",
            submissionDateLabel: "Date / heure de soumission",
            dateOrPeriodCoveredLabel: "Date ou période couverte",
            submittingEntityNameLabel: "Nom de l'entité soumettant",
            submittingEntityLEILabel: "LEI de l'entité soumettant",
            sizeOfSubmittingEntityLabel: "Taille de l'entité soumettant",
            leiStatusLabel: "Statut LEI",
            countryOfRegisteredOfficeLabel: "Pays du siège social",
            industrySectorLabel: "Secteur(s) d'activité",
            aboutLabel: "À propos",
            searchLabel: "Recherche",
        }
    }
});

export default strings;