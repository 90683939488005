import { useEffect, useState } from "react";
import JSONToTable from "../common/JSONtoTable";

interface CsvDisplayProps {
   fileContent: object[];
}

const CsvDisplay: React.FC<CsvDisplayProps> = ({ fileContent }) => {
  const [jsonDataForCsv, setJsonDataForCsv] = useState<object[] | null>(null);

  useEffect(() => {
    setJsonDataForCsv(fileContent);
  }, [fileContent]);

  return (
    <>
      {jsonDataForCsv && <JSONToTable data={jsonDataForCsv} />}
    </>
  );
}

export default CsvDisplay;
