import { CartCountContext } from "../../context/CartCountContext";
import { useContext } from "react";

const CartCount: React.FC= () => {
    const countContext = useContext(CartCountContext);
    if (!countContext) return null; 
    const { count } = countContext; 
    if (count === 0) return null; 
    return (
        <span
            style={{
                position: 'absolute',
                top: '-8px',
                left: '21px',
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '50%',
                padding: '1px 6px',
                fontSize: '10px',
                fontWeight: 'bold',
               
            }}
        >
            {count}
        </span>
    );
};

export default CartCount;
