import {Container, Dropdown, Nav, Navbar, NavItem, Offcanvas} from "react-bootstrap";
import {fetchUserPhoto,} from "../../services/UserInfoService/UserInfoService.ts";
import {useAuth} from "react-oidc-context";
import {useEffect, useRef, useState} from "react";
import {UserInfo} from "../../model/UserInfo.ts";
import {
    ABOUT_LABEL,
    ACCOUNT_PREFERENCES_LABEL,
    DASHBOARDS_LABEL,
    DATA_PUBLICATION_STATUS_LABEL,
    DATA_QUALITY_AND_MONITORING_LABEL,
    DOCUMENTATION_AND_SUPPORT_LABEL,
    ELEMENTS_VISIBILITY_CONTROL,
    FILE_RESUBMISSION,
    FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL,
    LOGIN_LABEL,
    LOGOUT_LABEL,
    LS_USER_PHOTO,
    MANAGE_ONLINE_HELP_AND_TUTORIALS,
    MANAGE_PUBLIC_REFERENCE_DOCUMENTATION,
    NOTIFICATION_MANAGEMENT,
    Roles,
    SUBMISSIONS_LABEL,
    SYSTEM_ADMINISTRATION_LABEL,
    SYSTEM_MONITORING_LABEL,
    TEST_SUBMISSION_OF_ESAP_INFO_LABEL,
    UPDATE_ON_USERS_REQUESTS
} from "../../constants/EsapConstants.ts";
import i18nc, {changeAppLanguage} from '../../config/i18nConfig';
import {useTranslation} from "react-i18next";
import {useUserInfo} from "../../hooks/useUserInfo.ts";
import accessTokenService from "../../services/AccessTokenService/AccessTokenService.ts";
import LogoutModal from "./LogOutModalProps.tsx";
import {useLocation, useNavigate} from 'react-router-dom';
import CartCount from "../Cart/CartCount.tsx";
const Header = () => {

    const auth = useAuth();
    const [userInfo, setUserInfo] = useState<UserInfo | null>();
    const [userPhoto, setUserPhoto] = useState<string | undefined>(undefined);
    const {t} = useTranslation();
    const {user} = useUserInfo();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    
    const changeLanguage = (languageCode: string | null) => {
        if (languageCode) {
            changeAppLanguage(languageCode);
        }
    };
    const location = useLocation(); 
    const [isCartPage, setIsCartPage] = useState(false);
    

    useEffect(() => {
        const isCart = location.pathname === '/cart';
        setIsCartPage(isCart);
    }, [location.pathname]);

    
    function onClickSignOut(): void {
        const logoutPageUrl = import.meta.env.VITE_REACT_APP_LOGOUT_URL;
        if (logoutPageUrl) {
            // setShowModal(true);
            const logoutToken = accessTokenService.clearAccessTokens();
            const logout_redirect_uri =  encodeURIComponent(window.location.origin)
            // auth.removeUser(); // clears localstorage
            // auth.signoutRedirect(); // redirects to the logout page
            // auth.signoutSilent().then(() => { //issue with chrome (blocked:other)
            //     setShowModal(false);
            window.location.href = `${logoutPageUrl}?1=1${logoutToken ? `&logoutToken=${logoutToken}` : ""}&post_logout_redirect_uri=${logout_redirect_uri}`;
            // });
        } else {
            console.error("Logout URL is not set");
        }
    }

    const handleLogin = async () => {

        const destination =  window.location.pathname === "/" ? "search" : window.location.pathname;
        const loginPageUrl = import.meta.env.VITE_REACT_APP_LOGIN_URL
        // const loginPageUrl= "http://localhost:35020/";

        if (!loginPageUrl) {
            console.warn("Login URL is not set, navigating to local login page");
            navigate("/user/login");
            return;
        }

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = loginPageUrl + "?destination=" + destination;

        const formData = {
            'entra_id': 'Log in',
            'form_id': 'openid_connect_login_form',
        };

        for (const key in formData) {
            if (Object.hasOwnProperty.call(formData, key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = formData[key as keyof typeof formData];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();
    };

    useEffect(() => {
        setUserInfo(user);
    }, [user]);

    const hasRoles = (roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    };

    useEffect(() => {
        const _userPhoto = localStorage.getItem(LS_USER_PHOTO);
        const accessToken = accessTokenService.getToken();
        if (_userPhoto === null) {
            if (user?.isAuthenticated) {
                fetchUserPhoto(accessToken ?? auth.user?.access_token).then((userPhoto) => {
                    if (userPhoto) {
                        const reader = new FileReader();
                        reader.addEventListener('load', () => {
                            localStorage.setItem(LS_USER_PHOTO, reader.result as string);
                        });
                        reader.addEventListener('loadend', () => {
                            setUserPhoto(localStorage.getItem(LS_USER_PHOTO) as string);
                        });
                        reader.readAsDataURL(userPhoto);
                    } else {
                        setUserPhoto(undefined);
                    }
                });
            }
        } else {
            setUserPhoto(_userPhoto);
        }
        return () => {
        }
    }, [user?.isAuthenticated, auth.user?.access_token]);

    const showOffcanvasRef = useRef(false);
    const navbarRef = useRef<HTMLDivElement | null>(null);
    const navbarRef1 = useRef<HTMLDivElement | null>(null);
    const lastMeasuredWidthRef = useRef<number | null>(null);

    useEffect(() => {
        const checkNavbarOverflow = () => {
            const navbarElement = navbarRef.current;
            if (!navbarElement) return;

            const originalDisplay = navbarElement.style.display;
            navbarElement.style.display = 'block';
            const scrollWidth = navbarElement.scrollWidth; // Total width of the content inside the navbar
            const clientWidth = navbarElement.clientWidth; // Visible width of the navbar
            navbarElement.style.display = originalDisplay;

            // Check if the navbar's content overflows (scrollWidth is greater than clientWidth)
            if (scrollWidth > clientWidth && !showOffcanvasRef.current) {
                showOffcanvasRef.current = true;
                lastMeasuredWidthRef.current = window.innerWidth;
                navbarElement.classList.add('hide');
                if (navbarRef1?.current && "classList" in navbarRef1.current) {
                    navbarRef1.current.classList.remove('hide');
                }
            } else if (scrollWidth <= clientWidth && showOffcanvasRef.current) {
                if (lastMeasuredWidthRef.current === null || window.innerWidth > lastMeasuredWidthRef.current) {
                    showOffcanvasRef.current = false; // Show expanded version
                    if (navbarRef1?.current && "classList" in navbarRef1.current) {
                        navbarRef1.current.classList.add('hide');
                    }
                    navbarElement.classList.remove('hide');
                }
            }
        };

        // Initial check on page load
        checkNavbarOverflow();

        // Check on window resize
        const handleResize = () => {
            checkNavbarOverflow();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>

            <LogoutModal show={showModal} handleClose={() => setShowModal(false)} />
            <Navbar ref={navbarRef} expand={true} className="SiteNav">
                <Container fluid>
                    <div>
                        <Navbar.Brand className="me-3" href="/">
                            <img src="/assets/logo.webp" alt="ESAP - European Single Access Point"/>
                        </Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="SiteMenu" className="p-0 border-0">
                        <i className="bi bi-list" aria-hidden="true"></i>
                        <span className="visually-hidden">Menu</span>
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id="SiteMenu"
                        aria-labelledby="SiteMenuTitle"
                        placement="end"
                        tabIndex={-1}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="TTCaps fw-semibold ps-2" id="SiteMenuTitle">
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div
                                className="d-flex flex-grow-1 justify-content-between flex-row align-items-center">
                                <Nav className="PrimaryNav border-bottom pb-3 pb-lg-0 mb-3 mb-lg-0">
                                    <Nav.Item className="px-1 mx-2">
                                        <Nav.Link className="link-dark" href="/about">
                                            {t(ABOUT_LABEL)}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="px-1 mx-2">
                                        <Nav.Link className="link-dark" href="/documentation-and-support">
                                            {t(DOCUMENTATION_AND_SUPPORT_LABEL)}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {hasRoles([Roles.DATA_FLOW_ROLE, Roles.ESAP_SUPPORT_ROLE]) && (
                                        <Nav.Item className="px-1 mx-2">
                                            <Dropdown as={Nav.Item}>
                                                <Dropdown.Toggle as={Nav.Link} className="link-dark">
                                                    {t(SUBMISSIONS_LABEL)}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/file-upload">
                                                        {t(FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL)}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="/report">
                                                        {t(TEST_SUBMISSION_OF_ESAP_INFO_LABEL)}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav.Item>
                                    )}
                                    {hasRoles([Roles.ESAP_DATA_MANAGER_ROLE, Roles.ESAP_DATA_ADMINISTRATOR_ROLE]) && (
                                    <NavItem className="px-1 mx-2">
                                        <Dropdown as={Nav.Item}>
                                            <Dropdown.Toggle as={Nav.Link} className="link-dark">
                                                {t(SYSTEM_ADMINISTRATION_LABEL)}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {hasRoles([Roles.ESAP_DATA_ADMINISTRATOR_ROLE]) && (
                                                    <>
                                                        <Dropdown.Item
                                                        href="/manage-online-help-and-tutorials">{t(MANAGE_ONLINE_HELP_AND_TUTORIALS)}</Dropdown.Item><Dropdown.Item
                                                        href="/manage-public-reference-documentation">{t(MANAGE_PUBLIC_REFERENCE_DOCUMENTATION)}</Dropdown.Item>
                                                    </>
                                                )}
                                                <Dropdown.Item
                                                    href="/file-resubmission">{t(FILE_RESUBMISSION)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/elements-visibility-control">{t(ELEMENTS_VISIBILITY_CONTROL)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/notification-management">{t(NOTIFICATION_MANAGEMENT)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/update-on-users-request">{t(UPDATE_ON_USERS_REQUESTS)}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                    )}
                                    {hasRoles([Roles.ESAP_DATA_MANAGER_ROLE, Roles.ESAP_DATA_ADMINISTRATOR_ROLE]) && (
                                    <NavItem className="px-1 mx-2">
                                        <Dropdown as={Nav.Item}>
                                            <Dropdown.Toggle as={Nav.Link} className="link-dark">
                                                {t(SYSTEM_MONITORING_LABEL)}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/dashboards">{t(DASHBOARDS_LABEL)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/data-quality-monitoring">{t(DATA_QUALITY_AND_MONITORING_LABEL)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/data-publication-status">{t(DATA_PUBLICATION_STATUS_LABEL)}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                    )}
                                </Nav>
                                <Nav className="pb-3 pb-lg-0 mb-3 mb-lg-0 justify-content-end align-items-center">
                                    <Nav.Item className={`px-1 mx-1`}>
                                        <Nav.Link
                                            className={`nav-link link-dark ${hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ? '' : 'disabled'} ${isCartPage ? 'active SiteNav' : ''}`}
                                            href={hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ? '/cart' : '#'}
                                            title="My Cart"
                                            aria-disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])}
                                            onClick={(e) => !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) && e.preventDefault()}>
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <i className="bi bi-bag me-2 me-lg-0 BiLG" aria-hidden="true"></i>
                                            <CartCount/>                                            
                                            <span className="d-inline d-lg-none">My Cart</span>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <div className="border-top mt-3 d-block d-lg-none"></div>
                                    <Nav.Item className="px-1 mx-1">
                                        <Dropdown onSelect={changeLanguage}>
                                            <Dropdown.Toggle variant="link" className="link-dark">
                                                <i className="bi bi-globe2 me-2 me-lg-0" aria-hidden="true"></i>
                                                &nbsp;{i18nc.language}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* TODO: Replace hardcoded languages */}
                                                <Dropdown.Item eventKey="en">EN</Dropdown.Item>
                                                <Dropdown.Item eventKey="fr">FR</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Item>
                                    <Nav.Item className="px-1 mx-1">
                                        <a
                                            className={`nav-link link-dark ${hasRoles([Roles.NOTIFICATION_ROLE]) ? '' : 'disabled'}`}
                                            href={hasRoles([Roles.NOTIFICATION_ROLE]) ? '/rss.xml' : '#'}
                                            title="RSS"
                                            aria-disabled={!hasRoles([Roles.NOTIFICATION_ROLE])}
                                            onClick={(e) => !hasRoles([Roles.NOTIFICATION_ROLE]) && e.preventDefault()}
                                        >
                                            <i className="bi bi-rss me-2 me-lg-0" aria-hidden="true"></i>
                                            &nbsp;RSS
                                        </a>
                                    </Nav.Item>
                                    {!user?.isAuthenticated ? (
                                        <Nav.Item className="px-1 mx-1">
                                            <a className="nav-link link-dark" href="#" onClick={handleLogin}>
                                                <i className="bi bi-box-arrow-in-left me-2 me-lg-0" aria-hidden="true"></i>&nbsp;{t(LOGIN_LABEL)}</a>
                                        </Nav.Item>
                                    ) : (

                                        <Dropdown className="px-1 mx-1 ">
                                            <Dropdown.Toggle variant="link" className="link-dark UserAccount"
                                                             id="dropdown-basic">
                                                {userPhoto ? (
                                                    <img src={userPhoto} alt="" className="bi bi-person-circle BiLG"
                                                         style={{width: '32px', height: '32px', borderRadius: '50%'}}/>
                                                ) : (
                                                    <i className="bi bi-person-circle BiLG"
                                                       style={{fontSize: '32px'}}></i>
                                                )}
                                                <span className="visually-hidden">User account</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Header>
                                                    <div className="d-flex flex-column">
                                                        <span>{userInfo?.name}</span>
                                                        <span>{userInfo?.rolesReadable.join(', ')}</span>
                                                    </div>
                                                </Dropdown.Header>
                                                <Dropdown.Item href="/account-preferences">
                                                    <i className="bi bi-sliders2 me-2 opacity-50"
                                                       aria-hidden="true"></i>
                                                    {t(ACCOUNT_PREFERENCES_LABEL)}
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={onClickSignOut}>
                                                    <i className="bi bi-box-arrow-right me-2 opacity-50"
                                                       aria-hidden="true"></i>
                                                    {t(LOGOUT_LABEL)}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </Nav>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <Navbar ref={navbarRef1} expand={false} className="SiteNav hide">
                <Container fluid>
                    <div>
                        <Navbar.Brand className="me-3" href="/">
                            <img src="/assets/logo.webp" alt="ESAP - European Single Access Point"/>
                        </Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="SiteMenu" className="p-0 border-0">
                        <i className="bi bi-list" aria-hidden="true"></i>
                        <span className="visually-hidden">Menu</span>
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id="SiteMenu"
                        aria-labelledby="SiteMenuTitle"
                        placement="end"
                        tabIndex={-1}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="TTCaps fw-semibold ps-2" id="SiteMenuTitle">
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div
                                className="d-flex flex-grow-1 flex-column ms-2">
                                <Nav className="PrimaryNav border-bottom pb-3 pb-lg-0 mb-3 mb-lg-0">
                                    <Nav.Item className="px-1 mx-2">
                                        <Nav.Link className="link-dark" href="/about">
                                            {t(ABOUT_LABEL)}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="px-1 mx-2">
                                        <Nav.Link className="link-dark" href="/documentation-and-support">
                                            {t(DOCUMENTATION_AND_SUPPORT_LABEL)}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {hasRoles([Roles.DATA_FLOW_ROLE, Roles.ESAP_SUPPORT_ROLE]) && (
                                        <Nav.Item className="px-1 mx-2">
                                            <Dropdown as={Nav.Item}>
                                                <Dropdown.Toggle as={Nav.Link} className="link-dark">
                                                    {t(SUBMISSIONS_LABEL)}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/file-upload">
                                                        {t(FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL)}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="/report">
                                                        {t(TEST_SUBMISSION_OF_ESAP_INFO_LABEL)}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav.Item>
                                    )}
                                    <NavItem className="px-1 mx-2">
                                        <Dropdown as={Nav.Item}>
                                            <Dropdown.Toggle as={Nav.Link} className="link-dark">
                                                {t(SYSTEM_ADMINISTRATION_LABEL)}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {hasRoles([Roles.ESAP_DATA_MANAGER_ROLE, Roles.ESAP_DATA_ADMINISTRATOR_ROLE]) && (
                                                    <>
                                                        <Dropdown.Item
                                                            href="/manage-online-help-and-tutorials">{t(MANAGE_ONLINE_HELP_AND_TUTORIALS)}</Dropdown.Item><Dropdown.Item
                                                            href="/manage-public-reference-documentation">{t(MANAGE_PUBLIC_REFERENCE_DOCUMENTATION)}</Dropdown.Item>
                                                    </>
                                                )}
                                                <Dropdown.Item
                                                    href="/file-resubmission">{t(FILE_RESUBMISSION)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/elements-visibility-control">{t(ELEMENTS_VISIBILITY_CONTROL)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/notification-management">{t(NOTIFICATION_MANAGEMENT)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/update-on-users-request">{t(UPDATE_ON_USERS_REQUESTS)}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                    {hasRoles([Roles.ESAP_DATA_MANAGER_ROLE, Roles.ESAP_DATA_ADMINISTRATOR_ROLE]) && (
                                    <NavItem className="px-1 mx-2">
                                        <Dropdown as={Nav.Item}>
                                            <Dropdown.Toggle as={Nav.Link} className="link-dark">
                                                {t(SYSTEM_MONITORING_LABEL)}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/dashboards">{t(DASHBOARDS_LABEL)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/data-quality-monitoring">{t(DATA_QUALITY_AND_MONITORING_LABEL)}</Dropdown.Item>
                                                <Dropdown.Item
                                                    href="/data-publication-status">{t(DATA_PUBLICATION_STATUS_LABEL)}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </NavItem>
                                    )}
                                </Nav>
                                <Nav className="pb-3 pb-lg-0 mb-3 mb-lg-0 align-items-start">
                                    <Nav.Item className="px-1 mx-1">
                                        <Nav.Link
                                            className={`nav-link link-dark ${hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ? '' : 'disabled'}`}
                                            href={hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ? '/cart' : '#'}
                                            title="My Cart"
                                            aria-disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])}
                                            onClick={(e) => !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) && e.preventDefault()}
                                        >
                                            <i className="bi bi-bag me-2 me-lg-0 BiLG" aria-hidden="true"></i>
                                            <span className="d-inline d-lg-none">My Cart</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <div className="border-top mt-3 d-block d-lg-none"></div>
                                    <Nav.Item className="px-1 mx-1">
                                        <Dropdown onSelect={changeLanguage}>
                                            <Dropdown.Toggle variant="link" className="link-dark">
                                                <i className="bi bi-globe2 me-2 me-lg-0" aria-hidden="true"></i>
                                                &nbsp;{i18nc.language}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* TODO: Replace hardcoded languages */}
                                                <Dropdown.Item eventKey="en">EN</Dropdown.Item>
                                                <Dropdown.Item eventKey="fr">FR</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Item>
                                    <Nav.Item className="px-1 mx-1">
                                        <a
                                            className={`nav-link link-dark ${hasRoles([Roles.NOTIFICATION_ROLE]) ? '' : 'disabled'}`}
                                            href={hasRoles([Roles.NOTIFICATION_ROLE]) ? '/rss.xml' : '#'}
                                            title="RSS"
                                            aria-disabled={!hasRoles([Roles.NOTIFICATION_ROLE])}
                                            onClick={(e) => !hasRoles([Roles.NOTIFICATION_ROLE]) && e.preventDefault()}
                                        >
                                            <i className="bi bi-rss me-2 me-lg-0" aria-hidden="true"></i>
                                            &nbsp;RSS
                                        </a>
                                    </Nav.Item>
                                    {!user?.isAuthenticated ? (
                                        <Nav.Item className="px-1 mx-1">
                                            <a className="nav-link link-dark" href="#" onClick={handleLogin}>
                                                <i className="bi bi-box-arrow-in-left me-2 me-lg-0" aria-hidden="true"></i>&nbsp;{t(LOGIN_LABEL)}</a>
                                        </Nav.Item>
                                    ) : (

                                        <Dropdown className="px-1 mx-1 ">
                                            <Dropdown.Toggle variant="link" className="link-dark UserAccount"
                                                             id="dropdown-basic">
                                                {userPhoto ? (
                                                    <img src={userPhoto} alt="" className="bi bi-person-circle BiLG"
                                                         style={{width: '32px', height: '32px', borderRadius: '50%'}}/>
                                                ) : (
                                                    <i className="bi bi-person-circle BiLG"
                                                       style={{fontSize: '32px'}}></i>
                                                )}
                                                <span className="visually-hidden">User account</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Header>
                                                    <div className="d-flex flex-column">
                                                        <span>{userInfo?.name}</span>
                                                        <span>{userInfo?.rolesReadable.join(', ')}</span>
                                                    </div>
                                                </Dropdown.Header>
                                                <Dropdown.Item href="/account-preferences">
                                                    <i className="bi bi-sliders2 me-2 opacity-50"
                                                       aria-hidden="true"></i>
                                                    {t(ACCOUNT_PREFERENCES_LABEL)}
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={onClickSignOut}>
                                                    <i className="bi bi-box-arrow-right me-2 opacity-50"
                                                       aria-hidden="true"></i>
                                                    {t(LOGOUT_LABEL)}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </Nav>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;