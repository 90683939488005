import {Modal, Button, Form, Spinner} from 'react-bootstrap';
import { LanguagesDropdownProps } from "../../../model/LanguagesDropdownProps.ts";
import {
    fetchOfficialLanguagesCodes,
} from "../../../services/TranslationService/DocumentTranslationService.ts";
import { Dispatch, FC, SetStateAction, useEffect } from "react";

interface TranslateModalProps {
    show: boolean;
    onHide: () => void;
    onTranslate: (from: string | null, to: string) => void;
    from: string | null;
    setFrom: Dispatch<SetStateAction<string | null>>;
    to: string;
    setTo: Dispatch<SetStateAction<string>>;
    isTranslating: boolean;
    fileDisplayName: string;
    fileSentForTranslation: boolean;
}

const TranslateModal: FC<TranslateModalProps> = ({ show, onHide, onTranslate, from, setFrom, to, setTo, fileDisplayName, fileSentForTranslation }) => {
    const officialLanguagesCodes: LanguagesDropdownProps[] = fetchOfficialLanguagesCodes();

    const matchedLanguages = fileDisplayName.match(/\[([^\]]+)\]/g)?.map((code) =>
        code.toLowerCase().replace(/\[|\]/g, '')
    ) || [];

    const availableFromLanguages = officialLanguagesCodes.filter(({ code }) => matchedLanguages.includes(code));
    const availableToLanguages = officialLanguagesCodes.filter(({ code }) => !matchedLanguages.includes(code));

    useEffect(() => {
        if (show) {
            const defaultFromLanguage = availableFromLanguages.find(({ code }) => code === 'en')?.code || availableFromLanguages[0]?.code || '';
            setFrom(defaultFromLanguage);
        }
    }, [show, setFrom, availableFromLanguages]);

    const handleTranslate = () => {
        onTranslate(from, to);
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="ModalTranslateTitle" aria-hidden="true" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Translate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="TRLangFrom">From:</Form.Label>
                                    <div className="input-group">
                                        <Button id="TRLangFromHelp" type="button" className="btn btn-sm border FormHelp" data-bs-toggle="tooltip" data-bs-title="More information about this field">
                                            <i className="bi bi-info-lg" aria-hidden="true" style={{ color: 'black' }}></i>
                                            <span className="visually-hidden">Help with this field</span>
                                        </Button>
                                        <Form.Select aria-describedby="TRLangFromHelp" id="TRLangFrom" value={from ?? ''} onChange={(e) => setFrom(e.target.value)}>
                                            <option value="">-</option>
                                            {availableFromLanguages.map((lang) => (
                                                <option key={lang.code} value={lang.code}>{lang.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="TRLangTo">To:</Form.Label>
                                    <div className="input-group">
                                        <Button id="TRLangToHelp" type="button" className="btn btn-sm border FormHelp" data-bs-toggle="tooltip" data-bs-title="More information about this field">
                                            <i className="bi bi-info-lg" aria-hidden="true" style={{ color: 'black' }}></i>
                                            <span className="visually-hidden">Help with this field</span>
                                        </Button>
                                        <Form.Select aria-describedby="TRLangToHelp" id="TRLangTo" value={to} onChange={(e) => setTo(e.target.value)}>
                                            <option value="">-</option>
                                            {availableToLanguages.map((lang) => (
                                                <option key={lang.code} value={lang.code}>{lang.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className="bg-light">
                <Button className="btn-primary" onClick={handleTranslate} disabled={to === '' || fileSentForTranslation}>
                    {fileSentForTranslation ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Translating...
                        </>
                        ) : (
                        <>
                            Translate
                        </>
                        )}

                </Button>
                <Button className="btn-light" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TranslateModal;