import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  downloadDataset,
  downloadDatasetFile,
  fetchDatasetFileset,
  fetchFileContents,
  calculateIfNeedsProcessing,
} from "../../services/DatasetDetailsService/DatasetDetailsService";
import { SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED } from "../../constants/EsapConstants";
import { DatasetResponse, File } from "../../model/InfoviewerResult";
import XmlDisplay from "./components/XmlDisplay";
import XbrlDisplay from "./components/XbrlDisplay";
import PlainTextDisplay from "./components/PlainTextDisplay";
import PdfDisplay from "./components/PdfDisplay";
import JsonDisplay from "./components/JsonDisplay";
import HtmlDisplay from "./components/HtmlDisplay";
import EsefZipDisplay from "./components/EsefZipDisplay";
import CsvDisplay from "./components/CsvDisplay";
import { FileTypes } from "../../model/FileTypes";
import CustomSpinner from "./common/Spinner";
import { Spinner, Stack } from "react-bootstrap";
import useCart from "../../services/CartSevice/CartService";
import { CartItem } from "../../model/CartItem.ts";
import { uuidGeneratorWithTimestamp } from "../../utils/uuidGenerator";
import { useAppInsights } from "../../hooks/useAppInsights";
import { CustomEvents, DataFlowTypes, Roles } from "../../constants/EsapConstants";
import { useTranslation } from "react-i18next";
import CommonMetadata from "./metadataDetailsComponents/CommonMetadata.tsx";
import DATPRIMetadata from "./metadataDetailsComponents/DATPRIMetadata.tsx";
import { CartCountContext } from "../../context/CartCountContext.tsx";
import DATSSRMetadata from "./metadataDetailsComponents/DATSSRMetadata.tsx";
import DATSSRView from "./dataflowsViews/DATSSRView.tsx";
import Toast from "./common/Toast.tsx";
import TranslateModal from "./components/TranslateModal.tsx";
import {
    sendFileForTranslation,
} from "../../services/TranslationService/DocumentTranslationService.ts";
import { useUserInfo } from "../../hooks/useUserInfo.ts";
import { UserInfo } from "../../model/UserInfo.ts";
import DrupalIcons from "./drupalIcons/DrupalIcons.tsx";
import { getCartLimit } from "../../services/CartSevice/BulkDownloadService.ts";

const DatasetDetails: React.FC = () => {
  const { datasetId } = useParams();
  const [datasetResponse, setDatasetResponse] = useState<DatasetResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { addCartItem, deleteCartItem, getCartItems } = useCart();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | Blob | object | object[] | null>(null);
  const [contentError, setContentError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [showDownloadErrorToast, setShowDownloadErrorToast] = useState(false);
  const [isInCart, setIsInCart] = useState<boolean>(false);
  const [cartLimit, setCartLimit] = useState<number>(5);
  const appInsights = useAppInsights();
  const { t } = useTranslation();
  const { count, setCount } = useContext(CartCountContext)!;
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFileTranslating, setIsFileTranslating] = useState(false);
  const [from, setFrom] = useState<string| null>(null);
  const [to, setTo] = useState<string>('');
 // state for modal spinner
  const [fileSentForTranslation, setFileSentForTranslation] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setFileSentForTranslation(false);
    setFrom(null);
    setTo('');
    setShowModal(false)
  };

  const {user} = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo | null>();
  useEffect(() => {

      setUserInfo(user);

  }, [user, setUserInfo]);
  const hasRoles = useCallback((roles: string[]) => {
    return userInfo?.roles.some((r) => roles.includes(r));
}, [userInfo]);

useEffect(() => {
  const fetchCartLimit = async () => {
    try {
      if (hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
          const { cartLimit } = await getCartLimit();
          setCartLimit(cartLimit);
      }
    } catch (error) {
      console.error("Failed to fetch cart limit:", error);
    }
  };

  fetchCartLimit();
}, [hasRoles]);
  
  useEffect(() => {
    const getDatasetFileset = async (datasetIdParam: string) => {
      if (datasetIdParam && hasRoles([Roles.INFORMATION_VIEWER_ROLE])) {
        const startTime = Date.now();
        const errorMessage = "Failed to fetch dataset files.";
        try {
          const result: DatasetResponse | null = await fetchDatasetFileset(datasetIdParam);
          setDatasetResponse(result);
          setError(result != null ? null : errorMessage);
          if(result) {
            const endTime = Date.now();
            createCustomDatasetEvent(CustomEvents.VIEW_DATASET, endTime - startTime, result.datasetRealId, result.dataFlowType);
          }
          setSelectedFile(null);
          const isDatasetInCart = getCartItems().some((cartItem) => cartItem.datasetId === datasetIdParam);
          setIsInCart(isDatasetInCart);
        } catch (err) {
          setError(errorMessage);
          console.error(err);
        }
      }
    };

    if (datasetId) {
      // Fetch dataset fileset along with metadata.
      getDatasetFileset(datasetId);
    } else {
      console.error("DatasetId was null/undefined");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, hasRoles]);

    const handleTranslationClick = async (_from: string | null, to: string) => {
        if (!datasetResponse?.datasetRealId || !selectedFile) {
                console.error("DatasetId or fileId is empty/null");
                return;
        }
        setFileSentForTranslation(true);
        await sendFileForTranslation(
            _from,
            to,
            datasetResponse.datasetRealId,
            selectedFile,
            setFileContent,
            setIsFileTranslating,
            handleModalClose
        );
    };

  const handleCartToggle = (datasetResponse: DatasetResponse) => {
    if(!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    const isInCart = getCartItems().some((cartItem) => cartItem.datasetId === datasetResponse.datasetRealId);

    if (isInCart) {
      deleteCartItem(datasetResponse.datasetRealId);
      setIsInCart(false);
      setCount(count > 0 ? count - 1 : 0);
    } else {
      const newCartItem: CartItem = {
        datasetId: datasetResponse.datasetRealId,
        RltdPrdFrDt: datasetResponse.metadata.rltdPrdFrDt,
        RltdPrdToDt: datasetResponse.metadata.rltdPrdToDt,
        RltdNttyLglPrsnLEI: datasetResponse.metadata.rltdNttyLglPrsnLEI,
        RltdNttyLglPrsnOrgMainNm: datasetResponse.metadata.rltdNttyLglPrsnOrgMainNm,
        RgltryDataTp: datasetResponse.metadata.rgltryDataTp.map((data) => ({
          Clssfctn: data.clssfctn,
          LglFrmwk: data.lglFrmwk,
        })),
      };
      addCartItem(newCartItem);
      setIsInCart(true);
      setCount(count + 1);
      //add to cart toast
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  };

  const createCustomFileEvent = (name: string, dataFormat: string) => {
    const eventUUID = uuidGeneratorWithTimestamp();
    appInsights.trackEvent({ name: name }, { eventUUID: eventUUID, dataFormat: dataFormat });
  };

  const createCustomDatasetEvent = (name: string, responseTime: number, datasetId: string, dataFlowType: string) => {
    const eventUUID = uuidGeneratorWithTimestamp();
    // TODO add searchEventUUID, type of information and legal framework properties in event
    appInsights.trackEvent(
      { name: name },
      { eventUUID: eventUUID, responseTime: responseTime / 1000, datasetId: datasetId, fileTypeCode: dataFlowType }
    );
  };

  async function handleClick(file: File) {
    if(!hasRoles([Roles.INFORMATION_VIEWER_ROLE])) {
      return;
    }
    setSelectedFile(file);
    setLoading(true);
    setContentError(null);
    if (!(datasetResponse?.datasetRealId && datasetResponse?.dataFlowType)) {
      setFileContent(null);
      setLoading(false);
      return;
    }
    try {
      const needProcessing: boolean = calculateIfNeedsProcessing(datasetResponse, file);
      const content = await fetchFileContents(datasetResponse.datasetRealId, file.fileId, file.fileType, needProcessing);
      if (content) {
        createCustomFileEvent(CustomEvents.VIEW_FILE, file.fileType);
        setFileContent(content);
        setContentError(null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setContentError(err.message);
      } else {
        setContentError("An error occurred while fetching the file.");
      }
      setFileContent(null);
    } finally {
      setLoading(false);
    }
  }
  
  async function handleDownload(datasetId: string, dataFlowType: string) {
    if(!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    const startTime = Date.now();
    if (datasetId) {
      setIsDownloading(true);
      setDownloadError(null);
      try {
        if (await downloadDataset(datasetId)) {
          const endTime = Date.now();
          createCustomDatasetEvent(CustomEvents.DOWNLOAD_DATASET, endTime - startTime, datasetId, dataFlowType);
        }
      } catch (error) {
        if (error instanceof Error) {
          setDownloadError(error.message);
        } else {
          setDownloadError("An error occurred while downloading the dataset.");
        }
        setShowDownloadErrorToast(true);
        setTimeout(() => {
          setShowDownloadErrorToast(false);
        }, 2000);
      } finally {
        setIsDownloading(false);
      }
    } else {
      console.error("datasetId was empty/null");
    }
  }

  async function handleDatasetFileDownload(datasetId: string, file: File | null) {
    if(!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    setIsFileDownloading(true);
    setDownloadError(null);
    try {
      if (await downloadDatasetFile(datasetId, file!.fileId, file!.fileName)) {
        createCustomFileEvent(CustomEvents.DOWNLOAD_FILE, file!.fileType);
      }
    } catch (error) {
      if (error instanceof Error) {
        setDownloadError(error.message);
      } else {
        setDownloadError("An error occurred while downloading the file.");
      }
      setShowDownloadErrorToast(true);
      setTimeout(() => {
        setShowDownloadErrorToast(false);
      }, 2000);
    } finally {
      setIsFileDownloading(false);
    }
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "30px",
          fontWeight: "bold",
          color: "red",
          textAlign: "center",
        }}
      >
        {error}
      </div>
    );
  }

  if (!datasetResponse) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <CustomSpinner />
      </div>
    );
  }

  return (
    <Stack>
      <div className="DTop TopDivider" />
      {/* Dataset title. */}
      <div className="DHeader  mt-5 pt-5 pb-4 mx-3 rounded-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="fs-3 mb-4">
                <span>
                  {[
                    datasetResponse.dataFlowType === DataFlowTypes.DATPRI
                      ? `[${datasetResponse.metadata.rgltryDataTp.map((regulatoryData) => regulatoryData.clssfctn).join(", ")}]`
                      : `[${datasetResponse.metadata.rgltryDataTp.map((regulatoryData) =>
                          t(`UI_INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION.${regulatoryData.clssfctn}`)
                        ).join(", ")}]`,
                    datasetResponse.metadata.rltdNttyLglPrsnOrgMainNm ? `[${datasetResponse.metadata.rltdNttyLglPrsnOrgMainNm}]` : "",
                    datasetResponse.metadata.rltdNttyLglPrsnLEI ? `[${datasetResponse.metadata.rltdNttyLglPrsnLEI}]` : "",
                    datasetResponse.metadata.rltdPrdToDt
                      ? `[${formatDate(datasetResponse.metadata.rltdPrdFrDt)} - ${formatDate(datasetResponse.metadata.rltdPrdToDt)}]`
                      : `[${formatDate(datasetResponse.metadata.rltdPrdFrDt)}]`, // Only show start date if end date is empty
                  ]
                    .filter(Boolean) // Remove any empty or falsy values
                    .join(" ")}
                </span>
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="p-0 d-flex justify-content-between rounded-1 DHInfo">
                <div className="d-flex justify-content-start">
                  <button
                    type="button"
                    disabled={isDownloading || !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])}
                    className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3 d-flex align-items-center"
                    onClick={() => handleDownload(datasetResponse?.datasetRealId, datasetResponse?.dataFlowType)}
                  >
                    {isDownloading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                        Downloading...
                      </>
                    ) : (
                      <>
                        <i className="bi bi-download me-2" aria-hidden="true"></i> Download
                      </>
                    )}
                  </button>
                  <OverlayTrigger placement="top" overlay={
                    (!isInCart && getCartItems().length >= cartLimit) ? <Tooltip id="tooltip-limit-reached">{t(SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED)}</Tooltip> : <></>
                  }>
                    <span style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                      <button
                        type="button"
                        className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3"
                        onClick={() => {
                          handleCartToggle(datasetResponse);
                        }}
                        disabled={isDownloading || !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) || (!isInCart && getCartItems().length >= cartLimit)}
                      >
                        <i className={isInCart ? "bi bi-bag-check-fill" : "bi bi-bag-plus"} aria-hidden="true"></i>
                        {isInCart ? " IN CART" : " ADD TO CART"}
                      </button>
                    </span>
                  </OverlayTrigger>
                </div>
                {/*Drupal Icons TODO Esealed value is mock*/}
                <DrupalIcons dataFlowType={datasetResponse.dataFlowType}  Esealed={true} HstrclData={datasetResponse.metadata.hstrclData} Vlntry={datasetResponse.metadata.hstrclData}  />     
              </div>
            </div>
          </div>
          {/*Metadata for diff dataflows*/}
          <div className="row">
            <div className="col-12">
              <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols mt-4">
                {/*DATPRI*/}
                {datasetResponse.dataFlowType === DataFlowTypes.DATPRI ? <DATPRIMetadata datasetResponse={datasetResponse} /> : null}
                {/*DATPXD*/}
                {datasetResponse.dataFlowType === DataFlowTypes.DATPXD ? (
                  <CommonMetadata
                    datasetResponse={datasetResponse}
                    taxonomySectorTranslationKey="UI_INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR"
                    legalFrameworkTranslationKey="UI_INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE."
                    rgltDataClssctnTraslationKey="UI_INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION"
                  />
                ) : null}
                {/*DATTRD*/}
                {datasetResponse.dataFlowType === DataFlowTypes.DATTRD ? (
                  <CommonMetadata
                    datasetResponse={datasetResponse}
                    taxonomySectorTranslationKey="UI_INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR"
                    legalFrameworkTranslationKey="UI_INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE."
                    rgltDataClssctnTraslationKey="UI_INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION"
                  />
                ) : null}
                {/*DATSSR*/}
                {datasetResponse.dataFlowType === DataFlowTypes.DATSSR ? <DATSSRMetadata datasetResponse={datasetResponse} /> : null}
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/*Files view for diff dataflows*/}
      {datasetResponse.dataFlowType === DataFlowTypes.DATSSR ? (
        <DATSSRView datasetResponse={datasetResponse} />
      ) : (
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                {/* Hidden div */}
                <div className="card border-0 bg-transparent d-none mb-5">
                  <div className="card-header bg-transparent border-0">
                    <h2 className="fs-6">Dataset Documents</h2>
                  </div>
                  <ul className="list-group list-group-flush border-top border-bottom">
                    {datasetResponse.files.map((file: File) => (
                      <li
                        key={file.fileId}
                        onClick={() => handleClick(file)}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <button>{file.fileName}</button>
                        <div className="btn-group" role="group" aria-label="Document actions">
                          <button type="button" className="btn btn-outline-light link-primary border-0" data-bs-toggle="tooltip" data-bs-title="View">
                            <i className="bi bi-eye-fill" aria-hidden="true"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-light link-primary border-0"
                            data-bs-toggle="tooltip"
                            data-bs-title="Download"
                          >
                            <i className="bi bi-download" aria-hidden="true"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-light link-primary border-0"
                            data-bs-toggle="tooltip"
                            data-bs-title="Translate"
                          >
                            <i className="bi bi-translate" aria-hidden="true"></i>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Visible Dataset Documents */}
                <h2 className="fs-6 m-0 py-2 px-3">Dataset Documents</h2>
                <div className="list-group list-group-flush border-top border-bottom DocList">
                  {datasetResponse.files.map((file: File) => (
                    <button
                      key={file.fileId}
                      disabled={!hasRoles([Roles.INFORMATION_VIEWER_ROLE])}
                      onClick={() => handleClick(file)}
                      className={"list-group-item list-group-item-action " + (selectedFile?.fileId === file.fileId ? "fw-bold" : "")}
                    >
                      <i className={selectedFile?.fileId === file.fileId ? "bi bi-file-text me-2" : ""} aria-hidden="true"></i>
                      {file.displayName}
                    </button>
                  ))}
                </div>
              </div>

              {/* File Content */}
              <div className="col-lg-8">
                <h2 className="fs-5 fw-normal text-primary m-0 pt-1 pb-2 px-3">
                  <i className="bi bi-file-text me-2" aria-hidden="true"></i> {selectedFile?.displayName}
                </h2>

                <div className="d-flex justify-content-end bg-primary rounded-1">
                  <button
                    type="button"
                    className="btn btn-primary border-0 rounded-0 px-2 px-lg-3 d-flex align-items-center"
                    onClick={() => handleDatasetFileDownload(datasetResponse?.datasetRealId, selectedFile)}
                    disabled={isFileDownloading || !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) || !selectedFile}
                  >
                    {isFileDownloading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                        Downloading...
                      </>
                    ) : (
                      <>
                        <i className="bi bi-download me-2" aria-hidden="true"></i> Download
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary border-0 rounded-0 px-2 px-lg-3"
                    disabled={isFileDownloading || isFileTranslating || !selectedFile || contentError !== null|| !selectedFile}
                    onClick={handleModalOpen}
                  >
                    {isFileTranslating ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Translating...
                        </>
                    ) : (
                        <>
                            <i className="bi bi-translate me-2" aria-hidden="true"></i> Translate
                        </>
                    )}
                </button>
                </div>

                <div
                  className="bg-white border rounded-1 mt-3"
                  style={{ height: "auto", maxHeight: "900px", padding: "20px", overflow: "auto", textAlign: "left" }}
                >
                  {contentError && <div style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{contentError}</div>}

                  {loading && !contentError ? (
                    <CustomSpinner />
                  ) : (
                    <>
                      {!selectedFile && (
                        <div className="text-center text-primary mt-3">
                          <p>Select file to be displayed</p>
                        </div>
                      )}
                      {selectedFile && fileContent && (
                        <div>
                          {selectedFile.fileType === FileTypes.XML && (typeof fileContent === "string" || typeof fileContent === "object") && (
                            <XmlDisplay fileContent={fileContent} convertedToJson={datasetResponse.dataFlowType === DataFlowTypes.DATSSR} />
                          )}
                          {selectedFile.fileType === FileTypes.XBRL && typeof fileContent === "string" && (
                            <XbrlDisplay fileContent={fileContent as string} />
                          )}
                          {selectedFile.fileType === FileTypes.ESEF_ZIP && typeof fileContent === "string" && (
                            <EsefZipDisplay fileContent={fileContent as string} />
                          )}
                          {selectedFile.fileType === FileTypes.CSV && Array.isArray(fileContent) && (
                            <CsvDisplay fileContent={Array.isArray(fileContent) ? fileContent : []} />
                          )}
                          {selectedFile.fileType === FileTypes.TXT && typeof fileContent === "string" && (
                            <PlainTextDisplay fileContent={fileContent as string} />
                          )}
                          {selectedFile.fileType === FileTypes.PDF && fileContent instanceof Blob && <PdfDisplay fileContent={fileContent as Blob} />}
                          {selectedFile.fileType === FileTypes.JSON && typeof fileContent === "object" && (
                            <JsonDisplay fileContent={fileContent as object} />
                          )}
                          {(selectedFile.fileType === FileTypes.HTML || selectedFile.fileType === FileTypes.XHTML) &&
                            typeof fileContent === "string" && <HtmlDisplay fileContent={fileContent as string} />}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Cart Success Toast */}
      <Toast message="The item has been added to your cart" isVisible={showToast} onClose={() => setShowToast(false)} type="success" />

      {/* Download Error Toast */}
      <Toast
        message={downloadError}
        isVisible={showDownloadErrorToast}
        onClose={() => {
          setShowDownloadErrorToast(false);
          setDownloadError(null);
        }}
        type="danger"
      />
      <TranslateModal
        show={showModal}
        onHide={handleModalClose}
        onTranslate={handleTranslationClick}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        isTranslating={isFileTranslating}
        fileDisplayName={selectedFile?.displayName ?? ""}
        fileSentForTranslation={fileSentForTranslation}
      />
    </Stack>
  );
};

export default DatasetDetails;
