import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { LOGIN_WITH_ENTRA_ID_LABEL } from "../../constants/EsapConstants.ts";
import { useTranslation } from "react-i18next";

const Login = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogin = () => {
        if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect().then(user => {
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/Home');
            }).catch(e => console.log(e));
        } else if (auth.isAuthenticated) {
            navigate('/notFound');
        }
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <button style={{padding: '0.5rem', margin: '1rem'}} onClick={handleLogin}>{t(LOGIN_WITH_ENTRA_ID_LABEL)}</button>
        </div>
    );
}

export default Login;