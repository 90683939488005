import { DatasetResponse } from "../../../model/InfoviewerResult";
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
interface DATSSRMetadataProps {
    datasetResponse: DatasetResponse;
}


const textInfoWithTooltip = (content : string|undefined) => {
    if (!content) return '';
    const length=30;
    const isTruncated = content.length > length;
    const displayedContent = isTruncated ? content.slice(0, length) + '...' : content;
    
    return (
      <OverlayTrigger  placement="top" overlay={<Tooltip>{content}</Tooltip>}>
             <span>{displayedContent}</span>
      </OverlayTrigger>);
};

const DATSSRMetadata: React.FC<DATSSRMetadataProps>=({ datasetResponse})=>{
    const {t} = useTranslation();

    return (
    
        <>
        <dt>COLLECTION BODY:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.colltnBody)}</dd>
        <dt>TYPE OF INFORMATION:</dt>
        <dd>{textInfoWithTooltip(datasetResponse.metadata.rgltryDataTp.map(regulatoryData => t(`UI_INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION.${regulatoryData.clssfctn}`)).join(', '))}</dd>
        <dt>LEGAL FRAMEWORK:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.rgltryDataTp.map(regulatoryType=>t(`UI_INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE.${regulatoryType.lglFrmwk}`)).join(', '))}</dd>
        <dt>SUBMISSION DATE/TIME:</dt> 
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.submissnDtTm)}</dd>
        <dt>INDUSTRY SECTOR OF POSITION HOLDER:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.rltdNttyLglPrsnSctr.map(sectorData=>t(`UI_INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR.${sectorData.nacetXnmySctr}`)).join(', '))}</dd>
        <dt>VERSION:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.vrsn)}</dd>  
        </>

    );};


  
    export default DATSSRMetadata;