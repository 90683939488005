import { useEffect, useState } from "react";
import Iframe from "../common/Iframe";

interface HtmlDisplayProps {
  fileContent: string;
}

const HtmlDisplay: React.FC<HtmlDisplayProps> = ({ fileContent }) => {
  const [isMounted, setIsMounted] = useState(false);

  // This effect will set `isMounted` to true after the component mounts
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // This effect will run after the component is mounted and whenever fileContent changes
  useEffect(() => {
    if (isMounted) {
      const iframeElement = document.getElementById(
        "viewerHtml"
      ) as HTMLIFrameElement;
      if (iframeElement) {
        iframeElement.srcdoc = fileContent;
      }
    }
  }, [isMounted, fileContent]);

  return (
    <>
      <Iframe id="viewerHtml" height="100%" width="100%" />
    </>
  );
}

export default HtmlDisplay;
