import { DatasetResponse } from "../../../model/InfoviewerResult";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DATSSRData } from "../../../model/DATSSRData";
import { dataForDATSSR } from "../../../services/DatasetDetailsService/DatasetDetailsService";
import { DataFlowTypes, FileTypes } from "../../../constants/EsapConstants";
import CustomSpinner from "../common/Spinner";

interface DATSSRViewProps {
  datasetResponse: DatasetResponse | null;
}

const textInfoWithTooltip = (content: string | undefined) => {
  if (!content) return "";
  const length = 30;
  const isTruncated = content.length > length;
  const displayedContent = isTruncated
    ? content.slice(0, length) + "..."
    : content;

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{content}</Tooltip>}>
      <span>{displayedContent}</span>
    </OverlayTrigger>
  );
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

const validForDATSSR = (datasetResponse: DatasetResponse | null): boolean => {
  if (datasetResponse) {
    return (
      datasetResponse.dataFlowType === DataFlowTypes.DATSSR &&
      datasetResponse.files.length == 1 &&
      datasetResponse.files[0].fileType === FileTypes.XML
    );
  } else {
    return false;
  }
};

const DATSSRView: React.FC<DATSSRViewProps> = ({ datasetResponse }) => {
  const [data, setData] = useState<DATSSRData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const errorMessage =
    "An error occurred while trying to display the information for this dataset.";

  useEffect(() => {
    const validDataset = validForDATSSR(datasetResponse);

    if (validDataset) {
      console.log("Valid request,fetching of data.");
      const fetchData = async () => {
        try {
          const result = await dataForDATSSR(
            datasetResponse!.datasetRealId,
            datasetResponse!.files[0].fileId
          );
          setData(result);
        } catch (err) {
          console.log(errorMessage);
          setError(errorMessage);
        }
      };
      fetchData();
    } else {
      console.log(errorMessage);
      setError(errorMessage);
    }
  }, [datasetResponse]);

  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <h3>{error}</h3>
      </div>
    );
  } else if (!data) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <CustomSpinner />
      </div>
    );
  } else {
    return (
      <>
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols">
                  <dt>POSITION HOLDER LEI:</dt>
                  <dd>{textInfoWithTooltip(data?.rltdNttyLglPrsnLEI)}</dd>
                  <dt>POSITION HOLDER NAME:</dt>
                  <dd>
                    {textInfoWithTooltip(data?.rltdNttyLglPrsnOrgMainNm) ||
                      textInfoWithTooltip(data?.rltdNttyNtrlPrsnNm)}
                  </dd>
                  <dt>POSITION HOLDER COUNTRY:</dt>
                  <dd>{textInfoWithTooltip(data?.homeCtry)}</dd>
                  <dt>DATE OR PERIOD COVERED:</dt>
                  <dd>
                    {data?.rltdPrdToDt
                      ? `${formatDate(data.rltdPrdFrDt)} - ${formatDate(data?.rltdPrdToDt)}`
                      : formatDate(datasetResponse!.metadata?.rltdPrdFrDt)}
                  </dd>
                  {data?.rltdIdrIsin ? (
                    <>
                      <dt>ISIN:</dt>
                      <dd>{textInfoWithTooltip(data.rltdIdrIsin)}</dd>{" "}
                    </>
                  ) : null}
                  <dt>NAME OF THE ISSUER:</dt>
                  <dd>{textInfoWithTooltip(data?.rltdIdrDesc)}</dd>
                  <dt>NET SHORT POSITION PERCENTAGE:</dt>
                  <dd>{textInfoWithTooltip(data?.ntShrtPstnPrc)}</dd>
                </dl>
              </div> {/*col*/}
            </div> {/*row*/}
          </div>  {/*container*/}
        </div>
      </>
    );
  }
};

export default DATSSRView;
