import { useState, useRef, useContext, useEffect, useCallback } from "react";
import useCart from "../../services/CartSevice/CartService.ts";
import { bulkDownloadRequestService } from "../../services/DatasetDetailsService/DatasetDetailsService.ts";
import { checkBulkAvailability, downloadBulk } from "../../services/CartSevice/BulkDownloadService.ts";
import { Col, Container, Row, Button, Alert, Spinner, Modal } from "react-bootstrap";
import { CartCountContext } from "../../context/CartCountContext.tsx";
import { useUserInfo } from "../../hooks/useUserInfo.ts";
import { UserInfo } from "../../model/UserInfo.ts";
import { Roles } from "../../constants/EsapConstants.ts";
import CustomSpinner from "../DatasetDetails/common/Spinner.tsx";
import { CartItem } from "../../model/CartItem.ts";
import { validateCurrentCart } from "../../services/CartSevice/CartValidationService.ts";
import { CartValidationResult } from "../../model/CartValidations.tsx";

const Cart: React.FC = () => {
  const [cartIsValid, setCartIsValid] = useState<boolean>(false);
  const validateInProgress = useRef<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [unavailableDatasets, setUnavailableDatasets] = useState<Set<string> | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { cartItems, deleteCartItem, deleteAllFromCart } = useCart();
  const [checkout, setCheckout] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [bulkAvailableMsg, setBulkAvailableMsg] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const intervalPollingRef = useRef<NodeJS.Timeout | null>(null);
  const intervalPollingCounterRef = useRef<number>(0);
  const { count, setCount } = useContext(CartCountContext)!;
  const POLLING_LIMIT: number = 6;
  const POLLING_INTERVAL_MS: number = 10000;
  const { user } = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setUserInfo(user);
  }, [user, setUserInfo]);

  const hasRoles = useCallback(
    (roles: string[]) => {
      return userInfo?.roles.some((r) => roles.includes(r));
    },
    [userInfo?.roles]
  );

  const validCartCheck = useCallback(async (cartDatasets: CartItem[]): Promise<CartValidationResult | null> => {
    if (cartDatasets.length === 0 || validateInProgress.current) {
      resetValidationState();
      return null;
    }

    validateInProgress.current = true;
    setIsLoading(true);
    const validationResult = await validateCurrentCart(cartDatasets);
    if (validationResult) {
      setCartIsValid(validationResult.isValid);
      setShowAlert(validationResult.showAlert);
      setValidationMessage(validationResult.message);
      setValidationError(validationResult.error);
      setUnavailableDatasets(new Set(validationResult.unavailableDatasets));
    }
    setIsLoading(false);
    validateInProgress.current = false;
    return validationResult;
  }, []);

  useEffect(() => {
    if (cartItems.length > 0 && hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      validCartCheck(cartItems);
    }
  }, [cartItems, validCartCheck, hasRoles]);

  const handleRemoveItem = async (techRcrdIdr: string) => {
    if(!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    const updatedCartItems = deleteCartItem(techRcrdIdr);
    setCount(count > 0 ? count - 1 : 0);
    setCheckout(false);
    if (updatedCartItems.length === 0) {
      deleteAllFromCart();
      resetValidationState();
      return;
    }
    await validCartCheck(updatedCartItems);
  };

  const resetValidationState = () => {
    setCartIsValid(false);
    setValidationMessage("");
    setValidationError(false);
    setUnavailableDatasets(null);
    setShowAlert(false);
  };

  const stopPolling = useCallback(
    (clearJustPolling?: boolean) => {
      if (intervalPollingRef.current) {
        clearInterval(intervalPollingRef.current);
        intervalPollingRef.current = null;
      }
      intervalPollingCounterRef.current = 0;
      setPolling(false);
      if (!clearJustPolling) {
        setCheckout(false);
      }
    },
    [setPolling, setCheckout]
  );

  // Function to download the dataset
  const downloadBulkZip = useCallback(
    async (requestId: string) => {
      if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
        return;
      }
      try {
        setDownloading(true);
        const blob = await downloadBulk(requestId);

        // Handle file download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = bulkZipName();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        deleteAllFromCart();
        setCount(0);
      } catch (err) {
        const errorMessage = (err as Error).message;
        setError(errorMessage);
        console.error(err);
      } finally {
        setDownloading(false);
        stopPolling(true);
      }
    },
    [hasRoles, deleteAllFromCart, setCount, stopPolling]
  );

  const pollForBulk = useCallback(
    async (requestId: string) => {
      try {
        const data = await checkBulkAvailability(requestId);
        const { success } = data;

        if (success) {
          // Stop polling if the requestId is available
          stopPolling();
          setBulkAvailableMsg(null);
          await downloadBulkZip(requestId);
        } else {
          intervalPollingCounterRef.current = intervalPollingCounterRef.current + 1;
          // Continue the polling if the requestId is NOT available
          if (intervalPollingCounterRef.current >= POLLING_LIMIT) {
            intervalPollingCounterRef.current = POLLING_LIMIT;
            setBulkAvailableMsg("Please check back later. The file is not yet available for download. If still not available please try again.");
            stopPolling();
          }
        }
      } catch (err) {
        setError("Error checking bulk availability.");
        stopPolling();
      }
    },
    [stopPolling, downloadBulkZip]
  );

  const startPollingBulkDownload = useCallback(
    async (requestId: string) => {
      if (polling) {
        return;
      }
      setPolling(true);
      setError(null);
      setBulkAvailableMsg("Bulk is not ready yet.");
      await pollForBulk(requestId);

      intervalPollingRef.current = setInterval(async () => {
        await pollForBulk(requestId); // Call again every 10 seconds
      }, POLLING_INTERVAL_MS);
    },
    [polling, pollForBulk]
  );

  const startCheckoutProcess = useCallback(async () => {
    if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) return;

    try {
      setShowModal(false);
      // Ensure cart item limits and size limits are not exceeded
      if (cartItems.length > 3) {
        setShowAlert(true);
        return;
      }

      setCheckout(true);
      setError(null);
      const datasetIds = new Set(cartItems.map((item) => item.datasetId));
      const { isValid, showAlert, message, error, unavailableDatasets, uuid} = await bulkDownloadRequestService(datasetIds);

      setCartIsValid(isValid);
      setShowAlert(showAlert);
      setValidationMessage(message);
      setValidationError(error);
      setUnavailableDatasets(unavailableDatasets);

      // If the cart is valid and a UUID is returned ->Start polling for file availability
      if (isValid && uuid) {
        await startPollingBulkDownload(uuid);
      } else {
        // Stop the checkout process if validation fails
        setCheckout(false);
      }
    } catch (error) {
      setShowModal(false);
      let errorMsg = "Unexpected error.";
      if (error instanceof Error) {
        errorMsg = error.message;
      }
      setError(errorMsg);
      setCheckout(false);
    }
  }, [cartItems, hasRoles, setCheckout, startPollingBulkDownload]);

  const bulkZipName = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}.zip`;
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = async () => {
    if (!cartIsValid && hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      const validationResult = await validCartCheck(cartItems);
  
      if (validationResult?.isValid) {
        setShowModal(true);
      } else {
        setValidationMessage(validationResult?.message || "Cart validation failed. Please review your cart.");
      }
    } else if (cartIsValid) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container fluid className="d-flex flex-column p-0">
      <Container fluid className="Intro py-2 flex-grow-0">
        <Row className="text-center m-0">
          <Col>
            <h1 className="fs-4">
              <small>My Cart</small>
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid="md">
        <Row className="my-5 justify-content-center">
          <Col xs={12} md={10}>
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div>Please hold while your cart is under validation..</div>
                <CustomSpinner />
              </div>
            )}
            {showAlert && (
              <div className="alert alert-warning mt-5 d-flex align-items-center justify-content-center" role="alert">
                <i className="bi bi-exclamation-diamond fs-1 me-1" aria-hidden="true"></i>
                <span className="flex-grow-1 text-center">{validationMessage}</span>
              </div>
            )}
            {validationError && (
              <div className="alert alert-danger mt-5 d-flex align-items-start py-0 pb-1">
                <i className="bi bi-exclamation-diamond-fill fs-1 me-1 " aria-hidden="true"></i>
                <span className="flex-grow-1 px-3 pt-4 ">
                  <p>{validationMessage}</p>
                </span>
              </div>
            )}
            {bulkAvailableMsg && (
              <Alert variant="warning" className="mb-5">
                {bulkAvailableMsg}
              </Alert>
            )}
            {error && (
              <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible className="mb-5">
                {error}
              </Alert>
            )}

            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <Row key={item.datasetId} className="align-items-center mb-3 border-bottom pb-2">
                  <Col xs={10} className="d-flex align-items-center">
                    {item.RgltryDataTp.map((data, dataIndex) => (
                      <span key={dataIndex} className={`text-primary ${unavailableDatasets?.has(item.datasetId) ? "text-muted" : ""}`}>
                        {[
                          data.Clssfctn,
                          item.RltdNttyLglPrsnOrgMainNm || "",
                          item.RltdNttyLglPrsnLEI,
                          item.RltdPrdToDt ? `${formatDate(item.RltdPrdFrDt)} - ${formatDate(item.RltdPrdToDt)}` : formatDate(item.RltdPrdFrDt),
                        ]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                    ))}
                  </Col>

                  <Col xs={2} className="text-end">
                    <Button
                      variant="dark"
                      size="sm"
                      disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) || downloading || polling || checkout || validationError || isLoading}
                      onClick={() => handleRemoveItem(item.datasetId)}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </Col>
                </Row>
              ))
            ) : (
              <div className="alert alert-info d-flex align-items-center" role="alert">
                <i className="bi bi-info-square " style={{ fontSize: "35px", color: "black", marginRight: "15px", marginTop: "-50px" }}></i>
                <div>
                  <p className="mb-1">Your cart is currently empty.</p>
                  <p className="mt-4 mb-0 ">To add items [short description].</p>
                  <p className="mb-0">
                    Please make sure that they do not exceed the allowed number (<b>#</b>) or total size (<b>#MB</b>).
                  </p>
                </div>
              </div>
            )}
            {cartItems.length > 0 && (
              <div className="d-flex justify-content-end mt-5">
                <Button
                  variant="primary"
                  onClick={handleOpenModal}
                  disabled={!cartItems.length || checkout || downloading || polling || validationError || !cartIsValid}
                >
                  {downloading ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                      Downloading...
                    </>
                  ) : checkout ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                      Processing...
                    </>
                  ) : (
                    "Checkout"
                  )}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Checkout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to proceed with checkout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])} onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])} variant="primary" onClick={startCheckoutProcess}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Cart;
