import { useEffect } from 'react';
import {initTranslations} from "../config/i18nConfig.ts";
import { TranslationContext } from './TranslationContext.tsx';


export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    useEffect(() => {
        (async () => {
            await initTranslations();
        })();
    }, []);

    return (
        <TranslationContext.Provider value={{}}>
            {children}
        </TranslationContext.Provider>
    );
};
