interface PlainTextDisplayProps {
    fileContent: string;
    }

const PlainTextDisplay: React.FC<PlainTextDisplayProps> = ({ fileContent }) => {
  return (
    <>
      <pre style={{ whiteSpace: "pre-wrap" }}>{fileContent}</pre>
    </>
  );
}

export default PlainTextDisplay;