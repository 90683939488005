import React from 'react';

interface IframeProps {
  src?: string;
  height?: string;
  width?: string;
  title?: string;
  id?: string;
}

const Iframe: React.FC<IframeProps> = (props) => {
  const inputProps = {
    src: props.src || '',
    height: props.height || '100%',
    width: props.width || '100%'
  };

  return (
    <iframe 
      title={props.title} 
      id={props.id} 
      src={inputProps.src} 
      height={inputProps.height} 
      width={inputProps.width}
    />
  );
}

export default Iframe;
