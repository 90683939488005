// import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
// import { createBrowserHistory } from "history";

const appInsightsConnectionString: string = import.meta.env.VITE_REACT_APP_INSIGHTS_CONNECTION_STRING || "";

// const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
// var clickPluginInstance = new ClickAnalyticsPlugin();
//    var clickPluginConfig = {
//      autoCapture: true
// };
const appInsights = new ApplicationInsights({
    config: {
        connectionString: appInsightsConnectionString,
        // *** Add the Click Analytics plug-in. ***
        extensions: [
          reactPlugin, 
          // clickPluginInstance
        ],
        // extensionConfig: {
          // [reactPlugin.identifier]: { history: browserHistory },
          // *** Add the Click Analytics plug-in. ***
          // [clickPluginInstance.identifier]: clickPluginConfig
        // }
    }
});
appInsights.loadAppInsights();

export { reactPlugin };