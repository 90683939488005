import {useNavigate} from "react-router-dom";

interface RBACProps {
    userRoles: string[];
    allowedRoles: string[];
    children: React.ReactNode;
}

export const RBAC: React.FC<RBACProps> = ({ userRoles, allowedRoles, children }) => {
    const navigate = useNavigate();

    if (allowedRoles.length === 0) {
        return <>{children}</>;
    }

    const hasAccess = userRoles?.some((role) => allowedRoles.includes(role));

    if (!hasAccess) {
        // Use navigate to redirect to the unauthorized page or any other route
        navigate('/unauthorized');
        return null; // Return null to prevent rendering the wrapped component
    }

    // Render the wrapped component
    return <>{children}</>;
};

