import { Icon } from "../../../model/DatasetDetailsIcons";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { drupalIconsConf } from "../../../services/DatasetDetailsService/DatasetDetailsService";
import { DataFlowTypes } from "../../../constants/EsapConstants";

interface DrupalIconsProps {
  dataFlowType?: string;
  Esealed?: boolean;
  HstrclData?: boolean;
  Vlntry?: boolean;
}

const DrupalIcons: React.FC<DrupalIconsProps> = ({ dataFlowType, Esealed, HstrclData, Vlntry }) => {
  const [icons, setIcons] = useState<Icon[]>([]);
  const [error, setError] = useState<string | null>(null);

  const loadDrupalIcons = async () => {
    const errorMessage = "Failed to fetch icons configurations.";
    try {
      const iconsData = await drupalIconsConf();
      setIcons(iconsData);
    } catch (err) {
      setError(errorMessage);
      console.error("Error fetching icons:", err);
    }
  };

  useEffect(() => {
    loadDrupalIcons();
  }, []);

  const renderIcons = () => {
    const iconElements: JSX.Element[] = [];

    const createIconElement = (key: string, icon: Icon) => (
      <OverlayTrigger key={key} placement="top" overlay={<Tooltip>{icon.field_disclaimer}</Tooltip>}>
        <div className="SRInfo py-2 px-2 text-info-emphasis">
          {icon.field_display === "Icon" ? (
            <i className={icon.field_icon_class}></i>
          ) : (
            <small>{icon.field_label}</small>
          )}
        </div>
      </OverlayTrigger>
    );

    if (dataFlowType !== DataFlowTypes.DATPRI && Esealed) {
      const sealIcon = icons.find(icon => icon.name === "UI_SEAL");
      if (sealIcon) {
        iconElements.push(createIconElement("seal", sealIcon));
      }
    }

    if (HstrclData) {
      const historicalIcon = icons.find(icon => icon.name === "UI_HISTORICAL");
      if (historicalIcon) {
        iconElements.push(createIconElement("historical", historicalIcon));
      }
    }

    if (Vlntry) {
      const voluntaryIcon = icons.find(icon => icon.name === "UI_VOLUNTARY");
      if (voluntaryIcon) {
        iconElements.push(createIconElement("voluntary", voluntaryIcon));
      }
    }

    return iconElements.length > 0 ? (
      <div className="d-flex justify-content-end align-items-center">
        {iconElements}
      </div>
    ) : null;
  };

  return (
    <>
      {!error ? renderIcons() : null}
    </>
  );
};

export default DrupalIcons;
