import i18nc from "i18next";
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, CK_LANGUAGE } from "../constants/EsapConstants.ts";
import Cookies from "js-cookie";
import { fetchTranslations } from "../services/TranslationService/TranslationService.ts";
import { FormattedTranslations, Translations } from "../model/Translations.ts";
import {initReactI18next} from "react-i18next";

const currentLanguage = Cookies.get(CK_LANGUAGE) || DEFAULT_LANGUAGE;

export function formatTranslations(translations: Translations): FormattedTranslations {
    const formattedTranslations: FormattedTranslations = {};
    const languagesSet = new Set<string>();

    if(!translations) {
        return formattedTranslations;
    }

    for (const item of translations) {
        const itemNameUpper = item.name.toUpperCase();

        for (const view of item.view) {
            const lang = view.langcode;
            if (!formattedTranslations[lang]) {
                formattedTranslations[lang] = { translation: {} };
            }
            formattedTranslations[lang].translation[itemNameUpper] = view.field_label === "" ? itemNameUpper : view.field_label;
            languagesSet.add(lang);
        }

        // Ensure all languages have the constant value as a fallback
        // when the translation is missing
        for (const lang of languagesSet) {
            if (!formattedTranslations[lang].translation[itemNameUpper]) {
                formattedTranslations[lang].translation[itemNameUpper] = itemNameUpper;
            }
        }
    }
    return formattedTranslations;
}

export const changeAppLanguage = async (languageCode: string) => {
    if (!languageCode) return;

    try {
        const translations = await fetchTranslations(languageCode);
        const formattedTranslations = formatTranslations(translations);

        await i18nc.init({
            resources: {
                [languageCode]: formattedTranslations[languageCode],
            },
            lng: languageCode,
            fallbackLng: FALLBACK_LANGUAGE,
        });

        Cookies.set(CK_LANGUAGE, languageCode);
    } catch (error) {
        console.error("Error changing language: ", error);
    }
};

export async function initTranslations() {
    await i18nc.use(initReactI18next)
        .init({
        lng: currentLanguage,
        fallbackLng: FALLBACK_LANGUAGE,
        interpolation: {
            escapeValue: false,
        },
    });

    const translations = await fetchTranslations(currentLanguage);
    if(translations) {
        const formattedTranslations = formatTranslations(translations);

        i18nc.addResourceBundle(currentLanguage, "translation", formattedTranslations[currentLanguage].translation, true);

        await i18nc.changeLanguage(currentLanguage);
    }
    Cookies.set(CK_LANGUAGE, currentLanguage);
}


initTranslations();

export default i18nc;
