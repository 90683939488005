import {Modal, Spinner} from "react-bootstrap";

interface LogOutModalProps {
    show: boolean;
    handleClose: () => void;
}
const LogoutModal = (props: LogOutModalProps) => {
    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Logging Out</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    <Spinner animation="border" role="status" className="me-2">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <span>Please wait while we log you out...</span>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default LogoutModal;