import React, {useEffect, useState,useContext} from "react";
import {SearchResult} from "../../model/SearchResult";
import {SearchFilter} from "../../model/SearchFilter";
import {searchForm} from "../../services/SearchService/SearchService";
import {downloadDataset} from "../../services/DatasetDetailsService/DatasetDetailsService";
import {Button, ButtonGroup, Card, Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {TypeOption} from "../../model/TypeOption.ts";
import {useTranslation} from "react-i18next";
import useCart from "../../services/CartSevice/CartService.ts";
import { CartItem } from "../../model/CartItem.ts";
import {
    CustomEvents,
    Roles,
    SEARCH_RESULTS,
    SEARCH_RESULTS_ACCESS_INFO_VIEWER,
    SEARCH_RESULTS_ADD_DATA_SET_TO_CART,
    SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED,
    SEARCH_RESULTS_DOWNLOAD_DATA_SET,
    SEARCH_RESULTS_ELECTRONIC_SEAL,
    SEARCH_RESULTS_INFO_RELATED_TO_HISTORICAL_DATA,
    SEARCH_RESULTS_INFO_RELATED_TO_VOLUNTARY_DATA,
    SEARCH_RESULTS_PAGINATION_FOOTER_FIRST,
    SEARCH_RESULTS_PAGINATION_FOOTER_LAST,
    SEARCH_RESULTS_PAGINATION_FOOTER_NEXT,
    SEARCH_RESULTS_PAGINATION_FOOTER_PREVIOUS,
    SEARCH_RESULTS_PAGING_OF,
    SEARCH_RESULTS_REMOVE_DATA_SET_FROM_CART,
    SEARCH_RESULTS_SHOW,
    SEARCH_RESULTS_SORT_BY, SEARCH_RESULTS_SORT_BY_INFO_TYPE,
    SEARCH_RESULTS_SORT_BY_PERIOD_COVERED,
    SEARCH_RESULTS_SORT_BY_RELEVANCE,
    SEARCH_RESULTS_SORT_BY_SUBMISSION_DATE
} from "../../constants/EsapConstants.ts";
import {useUserInfo} from "../../hooks/useUserInfo.ts";
import {UserInfo} from "../../model/UserInfo.ts";
import { useAppInsights } from "../../hooks/useAppInsights.ts";
import { uuidGeneratorWithTimestamp } from "../../utils/uuidGenerator";
import { CartCountContext } from '../../context/CartCountContext.tsx';
import Toast from "../DatasetDetails/common/Toast.tsx";

interface ISearchResultsProps {
    initialSearchResults: SearchResult;
    searchFields: SearchFilter;
    resetItemsPerPage: boolean;
    cartLimit: number;
    searchEventUUID: string | null;
}

// to be changed
const renderedItemsPerPage: TypeOption[] = [
    {label: "10", value: "10"},
    {label: "20", value: "20"},
    {label: "100", value: "100"},
]

// to be changed
const renderedSortBy: TypeOption[] = [
    {label: "relevance", value: SEARCH_RESULTS_SORT_BY_RELEVANCE},
    {label: "submissionDate", value: SEARCH_RESULTS_SORT_BY_SUBMISSION_DATE},
    {label: "periodCovered", value: SEARCH_RESULTS_SORT_BY_PERIOD_COVERED},
    {label: "typeOfInformation", value: SEARCH_RESULTS_SORT_BY_INFO_TYPE},
]

const renderTooltip = (id: string, message: string) => (
    <Tooltip id={id}>{message}</Tooltip>
);


const SearchResults: React.FC<ISearchResultsProps> = ({initialSearchResults, searchFields, resetItemsPerPage, cartLimit, searchEventUUID}) => {
    const [searchResults, setSearchResults] = useState<SearchResult>(initialSearchResults);
    const [sortBy, setSortBy] = useState<string>("relevance");
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const {t} = useTranslation();
    const {addCartItem, deleteCartItem, getCartItems} = useCart();
    const {user} = useUserInfo();
    const [userInfo, setUserInfo] = useState<UserInfo | null>();
    const { count, setCount } = useContext(CartCountContext)!;
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {

        setUserInfo(user);

    }, [user, setUserInfo]);
    const hasRoles = (roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    };

    const getCartTooltipText = (isInCart: boolean) => {
        if (!isInCart && getCartItems().length >= cartLimit) {
            return t(SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED)
        }
        return isInCart ? t(SEARCH_RESULTS_REMOVE_DATA_SET_FROM_CART) : t(SEARCH_RESULTS_ADD_DATA_SET_TO_CART)
    };

    const appInsights = useAppInsights();

    useEffect(() => {
        setSearchResults(initialSearchResults);
    }, [initialSearchResults]);

    useEffect(() => {
        if (resetItemsPerPage) {
            setItemsPerPage(10);
        }
    }, [resetItemsPerPage])

    const fetchSearchResults = async (page: number, items: number, sort: string) => {
        const results = await searchForm(searchFields, sort, items, page);
        setSearchResults(results);
    };

    const handleSortChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSortBy = event.target.value;
        setSortBy(newSortBy);
        setPageNumber(1);
        fetchSearchResults(1, itemsPerPage, newSortBy);
    };

    const handleResultsPerPageChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setPageNumber(1);
        fetchSearchResults(1, newItemsPerPage, sortBy);
    };

    const handleCartToggle = (datasetId: string) => {
        if(!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
            return;
        }

        const isInCart = getCartItems().some(cartItem => cartItem.datasetId === datasetId);

        if (isInCart) {
            deleteCartItem(datasetId);
            setCount(count > 0 ? count - 1 : 0);
            console.log(`Item with datasetId ${datasetId} removed from the cart.`);
        } else {
            const item = searchResults.results.find(result => result.document.datasetId === datasetId);

            if (item) {
                // Create a new CartItem and add it to the cart
                const newCartItem: CartItem = {
                    datasetId: item.document.datasetId,
                    RltdPrdFrDt: item.document.RltdPrdFrDt,
                    RltdPrdToDt: item.document.RltdPrdToDt,
                    RltdNttyLglPrsnLEI: item.document.RltdNttyLglPrsnLEI,
                    RltdNttyLglPrsnOrgMainNm: item.document.RltdNttyLglPrsnOrgMainNm,
                    RgltryDataTp: item.document.RgltryDataTp.map((data) => ({
                        Clssfctn: data.Clssfctn,
                        LglFrmwk: data.LglFrmwk
                    }))
                };
                addCartItem(newCartItem);
                setCount(count + 1);
                console.log(`Item with datasetID ${datasetId} added to the cart.`);
                //add to cart toast
                setShowToast(true);
                setTimeout(() => {
                  setShowToast(false);
                },2000);
            }
        }
        console.log('Current Cart Items:', getCartItems());
    };



    const handlePageChange = (action: string) => {
        let newPageNumber = pageNumber;
        const totalPages = Math.ceil(searchResults.totalCount / itemsPerPage);

        switch (action) {
            case "next":
                if (pageNumber < totalPages) {
                    newPageNumber += 1;
                }
                break;
            case "previous":
                if (pageNumber > 1) {
                    newPageNumber -= 1;
                }
                break;
            case "first":
                newPageNumber = 1;
                break;
            case "last":
                newPageNumber = totalPages;
                break;
            default:
                return;
        }

        setPageNumber(newPageNumber);
        fetchSearchResults(newPageNumber, itemsPerPage, sortBy);
    };

    const parseDescription = (description: string | null) => {
        if (description === null || description === undefined) {
            return [];
        }

        const regex = /<em>(.*?)<\/em>/g;
        const parts = description.split(regex);

        return parts.map((part, index) => {
            if (index % 2 === 1) {
                // This is the text inside <em> tags
                return (
                    <em key={index} style={{fontStyle: 'italic', fontWeight: 'bold'}}>{part}</em>
                );
            } else {
                return part;
            }
        });
    };

    const getCurrentRange = () => {
        const start = (pageNumber - 1) * itemsPerPage + 1; // Calculate the starting index (1-based)
        const end = Math.min(pageNumber * itemsPerPage, searchResults.totalCount); // Calculate the ending index, ensuring it does not exceed totalCount
        return `${start}-${end}`;
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatTime = (dateString: string): string => {
        const date = new Date(dateString);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const createDowloadEvent = (responseTime: number, datasetId: string, typeOfData: string) => {
        const eventUUID = uuidGeneratorWithTimestamp();
        // TODO add searchEventUUID, type of information and legal framework properties in event
        appInsights.trackEvent({name: CustomEvents.DOWNLOAD_DATASET}, {eventUUID: eventUUID, responseTime: responseTime / 1000, datasetId: datasetId, fileTypeCode: typeOfData, searchEventUUID: searchEventUUID});
    }

    return (
        <div className="SearchResults bg-white py-5">
            <Container>
                <Row>
                    <Col xs={12} lg={6}>
                        <strong>{getCurrentRange()}</strong> {t(SEARCH_RESULTS_PAGING_OF)}<strong> {searchResults.totalCount}</strong> {t(SEARCH_RESULTS)}.
                    </Col>

                    <Col xs={12} md={6} className="d-flex justify-content-end mt-3 mt-lg-0">
                        <div className="ResultsConfig">
                            {t(SEARCH_RESULTS_SORT_BY)}:
                            <select
                                className="fw-bold border-0 border-bottom bg-white ms-1"
                                aria-label="Results sorting options"
                                value={sortBy}
                                onChange={handleSortChange}
                            >
                                {renderedSortBy.map(option => (
                                    <option key={option.label} value={option.label}>
                                        {t(option.value)}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ResultsConfig ms-5">
                            {t(SEARCH_RESULTS_SHOW)}:
                            <select
                                className="fw-bold border-0 border-bottom bg-white ms-1"
                                aria-label="Number of results configuration"
                                value={itemsPerPage}
                                onChange={handleResultsPerPageChange}
                            >
                                {renderedItemsPerPage.map(option => (
                                    <option key={option.label} value={option.label}>
                                        {option.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {searchResults.results.slice(0, itemsPerPage).map((result, resultIndex) => {
                            // Determine whether this result is in the cart
                            const isInCart = getCartItems().some(cartItem => cartItem.datasetId === result.document.datasetId);
                            const disableCartButton = !isInCart && getCartItems().length >= cartLimit;
                            return (
                                <Card className="rounded-1 mt-5" key={resultIndex}>
                                    <Card.Body>
                                        <Card.Title className="fs-5 fw-semibold" >
                                            <a href={hasRoles([Roles.INFORMATION_VIEWER_ROLE]) ? `/dataset/${result.document.datasetId}` : `#`}>
                                                {result.document.RgltryDataTp.map((data, dataIndex) => (
                                                    <span key={dataIndex}>
                                                        {[
                                                            data.Clssfctn,
                                                            result.document.RltdNttyLglPrsnOrgMainNm || '',
                                                            result.document.RltdNttyLglPrsnLEI,
                                                            result.document.RltdPrdToDt
                                                                ? `${formatDate(result.document.RltdPrdFrDt)} - ${formatDate(result.document.RltdPrdToDt)}`
                                                                : formatDate(result.document.RltdPrdFrDt) // Only show start date if end date is empty
                                                        ]
                                                            .filter(Boolean) // Remove any empty or falsy values
                                                            .join(' ')}
                                                    </span>
                                                ))}
                                            </a>
                                        </Card.Title>
                                        <Card.Text>
                                            {
                                                parseDescription(result.description) && result.description !== null && result.description !== ''
                                                    ? parseDescription(result.description)
                                                    : result.document.content_introduction
                                            }
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="bg-lighter p-0 d-flex justify-content-between">
                                        <ButtonGroup className="d-flex justify-content-start">
                                            <OverlayTrigger placement="top"
                                                            overlay={renderTooltip('download', t(SEARCH_RESULTS_DOWNLOAD_DATA_SET))}>
                                                <Button variant="light" className="px-2 px-lg-3 rounded-0 border-end" disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])}
                                                    onClick={async () => {
                                                        if(!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
                                                            return;
                                                        }
                                                        const startTime = Date.now();
                                                        if (await downloadDataset(result.document.datasetId)) {
                                                            const endTime = Date.now();
                                                            createDowloadEvent(endTime - startTime, result.document.datasetId, result.document.TypeOfData ? result.document.TypeOfData : '');
                                                        }
                                                    }}
                                                >
                                                    <i className="bi bi-download" aria-hidden="true"></i>
                                                    <span className="visually-hidden">{t(SEARCH_RESULTS_DOWNLOAD_DATA_SET)}</span>
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top"
                                                            overlay={renderTooltip('cart', getCartTooltipText(isInCart))}>
                                                <span style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                                                    <Button
                                                        variant="light"
                                                        disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) || disableCartButton}
                                                        className="px-2 px-lg-3 rounded-0 border-end"
                                                        onClick={() => handleCartToggle(result.document.datasetId)}
                                                    >
                                                        <i className={isInCart ? "bi bi-bag-check-fill" : "bi bi-bag-plus"}
                                                        aria-hidden="true"></i>
                                                        <span
                                                            className="visually-hidden">{getCartTooltipText(isInCart)}</span>
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top"
                                                            overlay={renderTooltip('view-info', t(SEARCH_RESULTS_ACCESS_INFO_VIEWER))}>
                                                <Button variant="light"
                                                    disabled={!hasRoles([Roles.INFORMATION_VIEWER_ROLE])}
                                                    className="px-2 px-lg-3 rounded-0 border-end"
                                                    href={hasRoles([Roles.INFORMATION_VIEWER_ROLE]) ? `/dataset/${result.document.datasetId}`: `#`}
                                                >
                                                    <i className="bi bi-eye-fill" aria-hidden="true"></i>
                                                    <span
                                                        className="visually-hidden">{t(SEARCH_RESULTS_ACCESS_INFO_VIEWER)}</span>
                                                </Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                        <ButtonGroup className="d-flex justify-content-end align-items-center">
                                            <OverlayTrigger placement="top"
                                                            overlay={renderTooltip('qualified-seal', t(SEARCH_RESULTS_ELECTRONIC_SEAL))}>
                                                <div
                                                    className="SRInfo py-2 px-2 px-lg-3 border-start text-info-emphasis">
                                                    <span className="visually-hidden">Date / time when the data were checked as valid for the last time by ESAP:</span>
                                                    <i className="bi bi-patch-check-fill me-1"
                                                       aria-hidden="true"></i> {formatDate(result.document.ReceptionDate)} <small
                                                    className="d-none d-md-inline">{formatTime(result.document.ReceptionDate)}</small>
                                                </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top"
                                                            overlay={renderTooltip('historical-data', t(SEARCH_RESULTS_INFO_RELATED_TO_HISTORICAL_DATA))}>
                                                <div
                                                    className="SRInfo py-2 px-2 px-lg-3 border-start text-info-emphasis">
                                                    <span className="visually-hidden">Information in this dataset relates to historical data which pre-dates ESAP</span>
                                                    <i className="bi bi-clock-history" aria-hidden="true"></i>
                                                </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top"
                                                            overlay={renderTooltip('voluntary-data', t(SEARCH_RESULTS_INFO_RELATED_TO_VOLUNTARY_DATA))}>
                                                <div
                                                    className="SRInfo py-2 px-2 px-lg-3 border-start text-info-emphasis">
                                                    <span className="visually-hidden">Information in this dataset is voluntary</span>
                                                    <i className="bi bi-gift-fill" aria-hidden="true"></i>
                                                </div>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                    </Card.Footer>
                                </Card>
                            );
                        })}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Container className="Pagination d-flex justify-content-end mt-5 py-2 border-top">
                            <Button
                                variant="outline-light"
                                className="btn-sm btn-outline-light link-dark border-0 mx-1 d-none d-md-inline"
                                onClick={() => handlePageChange("first")}
                                disabled={pageNumber === 1}
                            >
                                <i className="bi bi-chevron-bar-left" aria-hidden="true"></i> {t(SEARCH_RESULTS_PAGINATION_FOOTER_FIRST)}
                            </Button>
                            <Button
                                variant="outline-light"
                                className="btn-sm btn-outline-light link-dark border-0 mx-1"
                                onClick={() => handlePageChange("previous")}
                                disabled={pageNumber === 1}
                            >
                                <i className="bi bi-chevron-left" aria-hidden="true"></i> {t(SEARCH_RESULTS_PAGINATION_FOOTER_PREVIOUS)}
                            </Button>
                            <Button
                                variant="outline-light"
                                className="btn-sm btn-outline-light link-dark border-0 mx-1"
                                onClick={() => handlePageChange("next")}
                                disabled={pageNumber * itemsPerPage >= searchResults.totalCount}
                            >
                                {t(SEARCH_RESULTS_PAGINATION_FOOTER_NEXT)} <i className="bi bi-chevron-right" aria-hidden="true"></i>
                            </Button>
                            <Button
                                variant="outline-light"
                                className="btn-sm btn-outline-light link-dark border-0 mx-1"
                                onClick={() => handlePageChange("last")}
                                disabled={pageNumber * itemsPerPage >= searchResults.totalCount}
                            >
                                {t(SEARCH_RESULTS_PAGINATION_FOOTER_LAST)} <i className="bi bi-chevron-bar-right" aria-hidden="true"></i>
                            </Button>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {/* add to cart toast */}
            <Toast message="The item has been added to your cart" isVisible={showToast} onClose={() => setShowToast(false)} type="success" />

        </div>
    );
};

export default SearchResults;
