import {TranslationProvider} from "./TranslationProvider.tsx";
import {UserInfoProvider} from "./UserInfoProvider.tsx";
import {AuthProvider} from "react-oidc-context";
import oidcConfig from "../config/oidcConfig.ts";

export const EsapProvider = ({ children }: { children: React.ReactNode }) => {

    return (
        <AuthProvider {...oidcConfig}>
            <TranslationProvider>
                <UserInfoProvider>
                    {children}
                </UserInfoProvider>
            </TranslationProvider>
        </AuthProvider>
    );
};