import { useEffect, useState } from "react";

interface PdfDisplayProps {
  fileContent: Blob;
}

const PdfDisplay: React.FC<PdfDisplayProps> = ({ fileContent }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  // Create blob URL when component mounts and revoke it when unmounting
  useEffect(() => {
    // Create the blob URL
    const url = URL.createObjectURL(fileContent);
    setFileUrl(url);

    // Cleanup function to revoke the URL when the component unmounts
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [fileContent]);

  
  return (
    <div style={{ height: "800px", width: "100%", overflow: "hidden", margin: 0, padding: 0 }}>
         <embed
           id="viewerPdf"
           src={fileUrl || ""}
           type="application/pdf"
           width="100%"
           height="100%"
           style={{ display: "block", border: "none" }}
         />
       </div>
  );
}

export default PdfDisplay;
