import { DatasetResponse } from "../../../model/InfoviewerResult";
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import CommonMetaData from "./CommonMetadata";
interface DATPRIMetadataProps {
    datasetResponse: DatasetResponse;
}


const textInfoWithTooltip = (content : string|undefined) => {
    if (!content) return '';
    const length=30;
    const isTruncated = content.length > length;
    const displayedContent = isTruncated ? content.slice(0, length) + '...' : content;
    
    return (
      <OverlayTrigger  placement="top" overlay={<Tooltip>{content}</Tooltip>}>
             <span>{displayedContent}</span>
      </OverlayTrigger>);
};

const DATPRIMetadata: React.FC<DATPRIMetadataProps>=({ datasetResponse})=>{
    
    return (
    
        <>
        <CommonMetaData datasetResponse={datasetResponse } taxonomySectorTranslationKey="UI_INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR"/>
        <dt>ISIN:</dt>
            <dd>{textInfoWithTooltip(datasetResponse?.metadata.rltdIdrIsin)}</dd>
        <dt>PERSONAL DATA</dt>
            <dd>{datasetResponse?.metadata.prsnlData?("Yes"):"No"}</dd>
             </>
    );};


  
    export default DATPRIMetadata;