import { useState } from "react";
import { CartItem } from "../../model/CartItem";
const LOCAL_STORAGE_CART_ITEMS_KEY = "cartItems";

const useCart = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    const storedCartItems = localStorage.getItem(LOCAL_STORAGE_CART_ITEMS_KEY);
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  const clearLocalStorageCart = () => {
    localStorage.removeItem(LOCAL_STORAGE_CART_ITEMS_KEY);
  };

  // Add an item to the cart
  const addCartItem = (item: CartItem) => {
    const updatedItems = [...cartItems, item];
    setCartItems(updatedItems);
    localStorage.setItem(LOCAL_STORAGE_CART_ITEMS_KEY, JSON.stringify(updatedItems));
  };

  //delete from cart
  const deleteCartItem = (datasetId: string) => {
    const updatedItems = cartItems.filter((item) => item.datasetId !== datasetId);
    setCartItems(updatedItems);
    localStorage.setItem(LOCAL_STORAGE_CART_ITEMS_KEY, JSON.stringify(updatedItems));
    if (updatedItems.length === 0) {
      clearLocalStorageCart();
    }
    return updatedItems; }

  // Get all items in the cart
  const getCartItems = () => {
    return cartItems;
  };

  const deleteAllFromCart = () => {
    setCartItems([]);
    clearLocalStorageCart();
  };

  return {
    cartItems,
    addCartItem,
    deleteCartItem,
    getCartItems,
    deleteAllFromCart
  };
};

export default useCart;
