import React, { useState } from 'react';
import { Col, Row, Form, Button, InputGroup, OverlayTrigger } from 'react-bootstrap';
import Select, { GroupBase, OnChangeValue, StylesConfig } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TypeOption } from "../../model/TypeOption.ts";
import { CustomStylesOptionType, SearchState } from "../Search/Search.tsx";
import { SearchFilter } from "../../model/SearchFilter.ts";
import { VisibilityFilter } from "../../model/VisibilityFilter.ts";
import { useTranslation } from "react-i18next";
import {
    SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER,
    SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER
} from "../../constants/EsapConstants.ts";
import { format } from "date-fns";
import { DataFlowType } from '../../model/DataFlowType.ts';

interface FieldGroupProps {
    fields: VisibilityFilter[];
    formFieldsState: SearchState;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleMultiSelectChange: (selectedOptions: OnChangeValue<TypeOption, true> | null, fieldName: string) => void; // Accept null
    handleDateChange: (date: Date | null, fieldName: string) => void;
    datePickers: { [key: string]: React.RefObject<DatePicker> };
    getOptionsForField: (fieldName: string) => TypeOption[];
    safeStringValue: (fieldName: keyof SearchFilter) => string;
    renderTooltip: (text: string) => JSX.Element;
    customStyles: StylesConfig<CustomStylesOptionType, true, GroupBase<CustomStylesOptionType>>;
    handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    getCheckboxValue: (value: boolean | string | string[] | DataFlowType | DataFlowType[]) => boolean;
}

const SearchField: React.FC<FieldGroupProps> = ({
                                                    fields,
                                                    formFieldsState,
                                                    handleInputChange,
                                                    handleMultiSelectChange,
                                                    handleDateChange,
                                                    datePickers,
                                                    getOptionsForField,
                                                    safeStringValue,
                                                    renderTooltip,
                                                    customStyles,
                                                    handleCheckboxChange,
                                                    getCheckboxValue,
                                                }) => {
    const { t } = useTranslation();
    const [inRow, setInRow] = useState<boolean>(false);

    const formatDate = (date: Date | null): string => {
        return date ? format(date, 'yyyy-MM-dd') : '';
    };

    const renderField = (field: VisibilityFilter) => {
        switch (field.fieldType) {
            case 'dropdown':
                switch (field.isMulti) {
                    case true:
                        return (
                            <div style={{ flex: 1 }}>
                                <Select
                                    isMulti
                                    options={getOptionsForField(field.fieldName).map(option => ({
                                        label: option.label,
                                        value: option.value,
                                        dataFlow: option.dataFlow
                                    }))}
                                    value={
                                        Array.isArray(formFieldsState.searchFormFields[field.fieldName]) &&
                                        (field.fieldName === "typeOfInformation" || field.fieldName === "sizeOfSubmittingEntity")
                                            ? (formFieldsState.searchFormFields[field.fieldName] as DataFlowType[]).map(value => ({
                                                label: getOptionsForField(field.fieldName).find(option => option.value === value.value)?.label || '',
                                                value: value.value,
                                                dataFlow: value.dataFlow // Include the dataFlow property
                                            }))
                                            : Array.isArray(formFieldsState.searchFormFields[field.fieldName])
                                                ? getOptionsForField(field.fieldName).filter(option =>
                                                    (formFieldsState.searchFormFields[field.fieldName] as string[]).includes(option.value)
                                                )
                                                : []
                                    }
                                    onChange={(newValue: OnChangeValue<TypeOption, true>) => {
                                        // Check if fieldName is one of the specified fields
                                        if (['sizeOfEntity', 'sizeOfSubmittingEntity', 'typeOfInformation'].includes(field.fieldName)) {
                                            // Convert selected options to DataFlowType[]
                                            const dataFlowSelections: DataFlowType[] = newValue
                                                ? (newValue as TypeOption[]).map(option => ({
                                                    value: option.value,
                                                    dataFlow: option.dataFlow || ''
                                                }))
                                                : [];
                                            // Call the handler with DataFlowType[]
                                            handleMultiSelectChange(dataFlowSelections as unknown as TypeOption[], field.fieldName);
                                        } else {
                                            // For other fields, just pass the selected options directly
                                            handleMultiSelectChange(newValue, field.fieldName);
                                        }
                                    }}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    placeholder=""
                                />
                            </div>
                        );

                    case false:
                        return (
                            <Form.Select
                                aria-describedby={`${field.fieldName}Help`}
                                name={field.fieldName}
                                value={(formFieldsState.searchFormFields[field.fieldName] as DataFlowType)?.value || ''}
                                onChange={(event) => {
                                    const selectedOption = getOptionsForField(field.fieldName).find(option => option.value === event.target.value);

                                    if (selectedOption) {
                                        const dataFlowObject: DataFlowType = {
                                            value: selectedOption.value,
                                            dataFlow: selectedOption.dataFlow || ''
                                        };
                                        handleInputChange({ target: { name: field.fieldName, value: dataFlowObject } } as unknown as React.ChangeEvent<HTMLInputElement>);
                                    }
                                }}
                            >
                                <option value="" hidden>{/* Empty value with hidden placeholder */}</option>
                                {getOptionsForField(field.fieldName).map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Form.Select>
                        );

                    default:
                        return null;
                }

            case 'text':
                return (
                    <Form.Control
                        aria-describedby={`${field.fieldName}Help`}
                        type="text"
                        name={field.fieldName}
                        value={safeStringValue(field.fieldName)}
                        onChange={handleInputChange}
                    />
                );

            case 'date':
                return (
                    <>
                        <Form.Control
                            type="text"
                            placeholder={t(SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER)}
                            aria-label="From"
                            name={`${field.fieldName}From`}
                            value={formatDate(safeStringValue(`${field.fieldName}From`) ? new Date(safeStringValue(`${field.fieldName}From`)) : null)}
                            onClick={() => datePickers[`${field.fieldName}From`]?.current?.setOpen(true)}
                            readOnly
                        />
                        <Button
                            variant="link-dark"
                            className="btn-sm bg-white border border-start-0"
                            onClick={() => datePickers[`${field.fieldName}From`]?.current?.setOpen(true)}
                        >
                            <i className="bi bi-calendar4" aria-hidden="true"></i>
                            <span className="visually-hidden">Date / time picker</span>
                        </Button>
                        <DatePicker
                            selected={safeStringValue(`${field.fieldName}From`) ? new Date(safeStringValue(`${field.fieldName}From`)) : null}
                            onChange={date => handleDateChange(date, `${field.fieldName}From`)}
                            ref={datePickers[`${field.fieldName}From`]}
                            onClickOutside={() => datePickers[`${field.fieldName}From`]?.current?.setOpen(false)}
                            maxDate={safeStringValue(`${field.fieldName}To`) ? new Date(safeStringValue(`${field.fieldName}To`)) : undefined}
                            className="d-none"
                        />
                        <Form.Control
                            type="text"
                            placeholder={t(SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER)}
                            aria-label="To"
                            name={`${field.fieldName}To`}
                            value={formatDate(safeStringValue(`${field.fieldName}To`) ? new Date(safeStringValue(`${field.fieldName}To`)) : null)}
                            onClick={() => datePickers[`${field.fieldName}To`]?.current?.setOpen(true)}
                            readOnly
                        />
                        <Button
                            variant="link-dark"
                            className="btn-sm bg-white border border-start-0"
                            onClick={() => datePickers[`${field.fieldName}To`]?.current?.setOpen(true)}
                        >
                            <i className="bi bi-calendar4" aria-hidden="true"></i>
                            <span className="visually-hidden">Date / time picker</span>
                        </Button>
                        <DatePicker
                            selected={safeStringValue(`${field.fieldName}To`) ? new Date(safeStringValue(`${field.fieldName}To`)) : null}
                            onChange={date => handleDateChange(date, `${field.fieldName}To`)}
                            ref={datePickers[`${field.fieldName}To`]}
                            onClickOutside={() => datePickers[`${field.fieldName}To`]?.current?.setOpen(false)}
                            minDate={safeStringValue(`${field.fieldName}From`) ? new Date(safeStringValue(`${field.fieldName}From`)) : undefined}
                            className="d-none"
                        />
                    </>
                );

            case 'checkbox':
                return (
                    <InputGroup.Text className="border-0 bg-transparent">
                        <Form.Check
                            type="checkbox"
                            id={field.fieldName}
                            aria-describedby={`${field.fieldName}Help`}
                            label={t(field.fieldLabel)}
                            checked={getCheckboxValue(formFieldsState.searchFormFields[field.fieldName])}
                            onChange={handleCheckboxChange}
                        />
                    </InputGroup.Text>
                );

            default:
                return null;
        }
    };

    const renderFieldGroup = (field: VisibilityFilter, index: number) => (
        <Form.Group className="mb-3" controlId={`SRCH${field.fieldName}`} key={index}>
            {field.fieldType !== 'checkbox' && (
                <Form.Label>{t(field.fieldLabel)}:</Form.Label>
            )}
            <InputGroup>
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(t(field.fieldLabel + "_TOOLTIP_TEXT"))}
                >
                    <Button variant="link" className="btn-sm border-end FormHelp">
                        <i className="bi bi-info-lg" aria-hidden="true"></i>
                        <span className="visually-hidden">Help with this field</span>
                    </Button>
                </OverlayTrigger>
                {renderField(field)}
            </InputGroup>
        </Form.Group>
    );

    return (
        <>
            {fields.map((field, index) => {
                if (field.fullWidth) {
                    return (
                        <Row key={`fullWidthRow-${index}`}>
                            <Col sm={6} className="pe-0">
                                {renderFieldGroup(field, index)}
                            </Col>
                        </Row>
                    );
                } else {
                    if (!inRow) {
                        setInRow(true);
                    }

                    return (
                        <Col sm={6} key={index}>
                            {renderFieldGroup(field, index)}
                        </Col>
                    );
                }
            })}
        </>
    );
};

export default SearchField;
