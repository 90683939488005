import { createContext, useState, ReactNode } from 'react';

interface EventUUIDContextType {
    eventUUID: string | null;
    setEventUUID: React.Dispatch<React.SetStateAction<string | null>>;
}

export const EventUUIDContext = createContext<EventUUIDContextType | null>(null);

export const EventUUIDProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [eventUUID, setEventUUID] = useState<string | null>(null);

    return (
        <EventUUIDContext.Provider value={{ eventUUID, setEventUUID }}>
            {children}
        </EventUUIDContext.Provider>
    );
};
