interface JsonDisplayProps {
  fileContent: object | string,
  convertedToJson: boolean
}

const XmlDisplay: React.FC<JsonDisplayProps> = ({ fileContent, convertedToJson }) => {
  return (
      <>
        {
          convertedToJson && typeof fileContent === 'object' ? (
            <pre>{JSON.stringify(fileContent, null, 2)}</pre>
          ) : 
          (
            <pre style={{ whiteSpace: "pre-wrap" }}>{fileContent.toString()}</pre>
          )
        }
      </>
  );
}

export default XmlDisplay;
