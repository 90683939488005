import {fetchWrapper} from "../FetchWrapperService/FetchWrapper.ts";
import {DocumentTranslationResult, DocumentTranslationStatus} from "../../model/DocumentTranslationResult.ts";
import {Dispatch, SetStateAction} from "react";
import {TranslationStatus} from "../../constants/EsapConstants.ts";
import {LanguagesDropdownProps} from "../../model/LanguagesDropdownProps.ts";

export async function translateDocument(from: string | null, datasetId: string | undefined, to: string, fileId: string): Promise<DocumentTranslationResult> {
    try {
        const url = import.meta.env.VITE_REACT_APP_AZURE_FUNC_ESAP_TRANSLATION_URL + `/api/BatchTranslateFunction?from=${from}&to=${to}&datasetId=${datasetId}&fileId=${fileId}`;
        return await fetchWrapper(url, { method: 'post'
        }).then(async (resp) => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw new Error('Error translating document');
            }
        });
    } catch (error) {
        console.error('Translate document error:', error);
        throw error;
    }
}

export async function getDocumentTranslationStatus(translationId: string): Promise<DocumentTranslationStatus> {
    try {
        const url = import.meta.env.VITE_REACT_APP_AZURE_FUNC_ESAP_TRANSLATION_STATUS_AND_DOWNLOAD_URL + `/api/FetchDocTranslation?jobId=${translationId}`;

        return await fetchWrapper(url, { method: 'post'
        }).then(async (resp) => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw new Error('Error fetching document translation status');
            }
        });
    } catch (error) {
        console.error('Fetch document translation status error:', error);
        throw error;
    }
}

export async function getTranslatedDocument(documentUrl: string, fileType: string): Promise<string | Blob | object | object[] | null> {
    const url: string = import.meta.env.VITE_REACT_APP_AZURE_FUNC_ESAP_TRANSLATION_STATUS_AND_DOWNLOAD_URL
        + `/api/DownloadFile?fileUrl=${documentUrl}`;

    try {
        return await fetchWrapper(url, { method: 'post'

        }).then(async (resp) => {
            if (resp.ok) {
                switch (fileType) {
                    case 'XML':
                    case 'XBRL':
                    case 'TXT':
                    case 'HTML':
                    case 'XHTML':
                    case 'ESEF_ZIP':
                        return await resp.text();
                    case 'PDF':
                        return await resp.blob();
                    case 'CSV':
                    case 'JSON':
                        return await resp.json();

                    default:
                        throw new Error('Unsupported file type: ' + fileType);
                }
            } else {
                throw new Error('Error fetching translated document.');
            }
        });
    } catch (error) {
        console.error('Fetch translated document error:', error);
        throw error;
    }
}

export const sendFileForTranslation = async (
    _from: string | null,
    to: string,
    datasetRealId: string | undefined,
    selectedFile: { fileId: string; fileType: string } | null,
    setFileContent: Dispatch<SetStateAction<string | Blob | object | object[] | null>>,
    setIsFileTranslating: Dispatch<SetStateAction<boolean>>,
    handleModalClose: () => void
) => {
    if (!datasetRealId || !selectedFile?.fileId) {
        console.error("DatasetId or fileId is empty/null");
        return;
    }
    try {
        const response: DocumentTranslationResult = await translateDocument(_from, datasetRealId, to, selectedFile.fileId);

        if (response.blobUrl) {
            const translatedDocument = await getTranslatedDocument(response.blobUrl, selectedFile.fileType);
            setIsFileTranslating(false);
            handleModalClose();
            setFileContent(translatedDocument);
        } else {
            const translationJobId: string = response.id;
            if (!translationJobId) {
                console.error("Translation job id is empty/null");
                return;
            }

            handleModalClose();
            setIsFileTranslating(true);
            await pollTranslationStatus(translationJobId, selectedFile, setFileContent, setIsFileTranslating);
        }
    } catch (error) {
            console.error("Error sending file for translation:", error);
            setFileContent("An error occurred while sending the file for translation.");
            setIsFileTranslating(false);
            handleModalClose();
        }
    };

const pollTranslationStatus = async (
    id: string,
    selectedFile: { fileType: string } | null,
    setFileContent: Dispatch<SetStateAction<string | Blob | object | object[] | null>>,
    setIsFileTranslating: Dispatch<SetStateAction<boolean>>
) => {
    const intervalId = setInterval(async () => {
        try {
            const response: DocumentTranslationStatus = await getDocumentTranslationStatus(id);
            const status = response.status;

            console.log("-------------------------------------------");
            console.log(`Checking translation status for id: ${id}`);
            console.log(`Translation status: ${status}`);
            console.log("-------------------------------------------");

            if (status !== TranslationStatus.RUNNING && status !== TranslationStatus.NOT_STARTED) {
                clearInterval(intervalId);
                console.log(`Translation complete with status: ${status}`);

                if (status === TranslationStatus.SUCCEEDED && response.targetFile && selectedFile) {
                    const translatedDocument = await getTranslatedDocument(response.targetFile, selectedFile.fileType);
                    setFileContent(translatedDocument);
                } else {
                    const errorMessage = response.message || "Translation status not recognized.";
                    console.error(errorMessage);
                    setFileContent(errorMessage);
                }

                setIsFileTranslating(false);
            }
        } catch (error) {
            console.error("Error fetching translation status:", error);
            setFileContent("Error fetching translation status.");
            setIsFileTranslating(false);
            clearInterval(intervalId);
        }
    }, 3000);
};

export const fetchOfficialLanguagesCodes = (): LanguagesDropdownProps[] => {
    //TODO: Fetch from API
    return [
        { name: "Bulgarian", code: "bg" },
        { name: "Croatian", code: "hr" },
        { name: "Czech", code: "cs" },
        { name: "Danish", code: "da" },
        { name: "Dutch", code: "nl" },
        { name: "English", code: "en" },
        { name: "Estonian", code: "et" },
        { name: "Finnish", code: "fi" },
        { name: "French", code: "fr" },
        { name: "German", code: "de" },
        { name: "Greek", code: "el" },
        { name: "Hungarian", code: "hu" },
        { name: "Irish", code: "ga" },
        { name: "Italian", code: "it" },
        { name: "Latvian", code: "lv" },
        { name: "Lithuanian", code: "lt" },
        { name: "Maltese", code: "mt" },
        { name: "Polish", code: "pl" },
        { name: "Portuguese", code: "pt" },
        { name: "Romanian", code: "ro" },
        { name: "Slovak", code: "sk" },
        { name: "Slovenian", code: "sl" },
        { name: "Spanish", code: "es" },
        { name: "Swedish", code: "sv" }
    ];
}