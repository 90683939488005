
interface JSONToTableProps {
  data: object[];
}

let uniqueKeyindex = 0;

const JSONToTable: React.FC<JSONToTableProps> = ({ data }) => {
  // Cast data to Record<string, unknown>[] for use in these functions
  function getHeaders(data: object[]): JSX.Element[] {
    if (data.length > 0) {
      const firstRow = data[0] as Record<string, unknown>;
      return Object.keys(firstRow).map((key) => {
        uniqueKeyindex++;
        return <th key={uniqueKeyindex}>{key}</th>;
      });
    }
    return [];
  }

  function getRows(data: object[]): JSX.Element[] {
    return data.map((row) => {
      uniqueKeyindex++;
      const rowAsRecord = row as Record<string, unknown>;
      return (
        <tr key={uniqueKeyindex}>
          {getCells(rowAsRecord)}
        </tr>
      );
    });
  }

  function getCells(row: Record<string, unknown>): JSX.Element[] {
    return Object.values(row).map((value) => {
      uniqueKeyindex++;
      return <td key={uniqueKeyindex}>{String(value)}</td>;
    });
  }

  return (
    <table>
      <thead>
        <tr>{getHeaders(data)}</tr>
      </thead>
      <tbody>
        {getRows(data)}
      </tbody>
    </table>
  );
}

export default JSONToTable;
