import { useEffect, useState } from "react";
import Iframe from "../common/Iframe";

interface XbrlDisplayProps {
  fileContent: string;
}

const XbrlDisplay: React.FC<XbrlDisplayProps> = ({ fileContent }) => {
  const [isMounted, setIsMounted] = useState(false);

  // This effect will set `isMounted` to true after the component mounts
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // This effect will run after the component is mounted and whenever fileContent changes
  useEffect(() => {
    if (isMounted) {
      const iframeElement = document.getElementById(
        "viewerHtml"
      ) as HTMLIFrameElement;
      if (iframeElement) {
        iframeElement.srcdoc = fileContent;
      }
    }
  }, [isMounted, fileContent]);

  return <Iframe id="viewerHtml" height="800px" width="100%"></Iframe>;
}

export default XbrlDisplay;
