export enum FileTypes {
    XML = 'XML',
    XBRL = 'XBRL',
    ESEF_ZIP = 'ESEF_ZIP',
    CSV = 'CSV',
    TXT = 'TXT',
    PDF = 'PDF',
    JSON = 'JSON',
    HTML = 'HTML',
    XHTML = 'XHTML',
  }