// src/utils/fetchWrapper.ts
import accessTokenService from "../AccessTokenService/AccessTokenService.ts";

export async function fetchWrapper(url: string, options: RequestInit = {}) {
    const token = accessTokenService.getToken();
    const headers: Record<string, string> = {
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        ...options.headers as Record<string, string>,
    };
    const fetchOptions: RequestInit = {
        ...options,
        headers,
    };

    try {
        return await fetch(url, fetchOptions);
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}
